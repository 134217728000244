import { Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth } from "../../../app/authHooks";
import { useNavigate } from "react-router";
import { Eye, EyeClosed } from "iconoir-react";
import { useState } from "react";

const initialValues = {
  resetPassword: "",
  confirmPassword: ""
};

const verifySchema = yup.object().shape({
  resetPassword: yup.string().required("Reset Password is required."),
  confirmPassword: yup.string().required("Confirm Password is required.")
});

const VerifyForm = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = theme.palette;
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(true);
  const handleFormSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      await auth.verifyTotp(values.resetPassword);
      navigate("/");
    } catch (e: any) {
      console.error(e);
    }
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>, values: any, handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void) => {
    handleChange(event);
    const confirmPassword = event.target.value;
    if (confirmPassword.length<1) {
      setIsError(true);
      setErrorMessage("");
    }
    if (confirmPassword !== values.resetPassword) {
      setIsError(true);
      setErrorMessage("Confirm Password mismatch.");
    }
    else {
      setIsError(false);
      setErrorMessage("");
      values.confirmPassword = confirmPassword;
    }
  };

  const handleToggleResetPasswordVisibility = () => {
    setShowResetPassword((prev) => !prev);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  return (
    <Box sx={{ background: colors.primary.light, minHeight: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ marginBottom: 4 }}>
        <Box
          component="img"
          alt="ss&c-logo"
          src="../../../assets/images/ssc-hubwise-logo.svg"
          sx={{
            width: 250,
            height: "auto"
          }}
        />
      </Box>
      <Box
        sx={{
          p: 5,
          background: colors.grey[900],
          borderRadius: 4,
          boxShadow: "1px 3px 10px rgba(202, 202, 202, 0.83)",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <Typography variant="h4" sx={{ fontWeight: 700, color: colors.grey[400] }}>Admin Platform</Typography>
          <Typography variant="h5" sx={{ fontWeight: 700 }}>Reset Password</Typography>
        </Box>

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={verifySchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 4,
                    marginTop: 5
                  }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    type={showResetPassword ? "text" : "password"}
                    label="Reset Password"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.resetPassword}
                    name="resetPassword"
                    error={!!touched.resetPassword && !!errors.resetPassword}
                    helperText={!!touched.resetPassword && errors.resetPassword}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{
                          fontSize: 14,
                          ":hover": {
                            cursor: "pointer"
                          }
                        }}>
                          <IconButton sx={{
                            p: 0,
                            border: 0,
                            boxShadow: 0,
                            borderRadius: 0,
                            ":hover": {
                              backgroundColor: colors.grey[900],
                              boxShadow: 0
                            }
                          }}
                          onClick={handleToggleResetPasswordVisibility}
                          >{showResetPassword ? <Eye /> : <EyeClosed />}</IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    type={showConfirmPassword ? "text" : "password"}
                    label="Confirm Password"
                    size="small"
                    onBlur={handleBlur}
                    onChange={(event) => handleConfirmPasswordChange(event as React.ChangeEvent<HTMLInputElement>, values, handleChange)}
                    value={values.confirmPassword}
                    name="confirmPassword"
                    error={!!touched.confirmPassword && !!errors.confirmPassword}
                    helperText={!!touched.confirmPassword && errors.confirmPassword}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{
                          fontSize: 14,
                          ":hover": {
                            cursor: "pointer"
                          }
                        }}>
                          <IconButton sx={{
                            p: 0,
                            border: 0,
                            boxShadow: 0,
                            borderRadius: 0,
                            ":hover": {
                              backgroundColor: colors.grey[900],
                              boxShadow: 0
                            }
                          }}
                          onClick={handleToggleConfirmPasswordVisibility}
                          >{showConfirmPassword ? <Eye /> : <EyeClosed />}</IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                {isError && errorMessage ? <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}><Typography variant="h5" sx={{ color: colors.redAccent[500] }}>{errorMessage}</Typography></Box> : null}
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={isLoading || isError || errorMessage.length > 0}
                    endIcon={isLoading ? <CircularProgress size={20} /> : null}
                  >
                    {isLoading ? "Resetting Password" : "Confirm Reset Password"}
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </Box>
    </Box>
  );
};

export default VerifyForm;
