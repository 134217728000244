import { javascript } from "@codemirror/lang-javascript";
import { Box, CircularProgress, Link, useTheme } from "@mui/material";
import Header from "components/Header";
import { useLocation, useNavigate, useParams } from "react-router";
import CodeMirror, { EditorView } from "@uiw/react-codemirror";
import { useGetUserEventDetailQuery } from "../api/user-activity.generated";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const ADVISER = "adviser";
const INVESTOR = "investor";

function UsersActivityDetail() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { userId, eventId } = useParams<{ userId: string; eventId: string }>();
  const location = useLocation();

  const username = location.state && location.state.username ? location.state.username : "";
  let userType;
  if (location.pathname.includes(ADVISER)) {
    userType = ADVISER;
  }

  if (location.pathname.includes(INVESTOR)) {
    userType = INVESTOR;
  }

  userType = userType ?? ADVISER;

  if (!eventId || !userId) {
    return <ErrorMessage />;
  }

  const eventIdNumber = parseInt(eventId);

  const getSubTitle = () => {
    return `Event ID: ${eventId} : ${username}`;
  };

  const {
    data: getUserEventDetail,
    isLoading,
    isError
  } = useGetUserEventDetailQuery({ id: eventIdNumber, userType });

  console.log(getUserEventDetail);

  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <Box m="20px">
      <Box sx={{ cursor: "pointer", fontSize: "14px", lineHeight: "20px", marginBottom: "16px" }}>
        <Link underline="hover" onClick={() => navigate(`/${userType}-users/user-activity/${userId}`, { state: { username: username } } )}>
          <ArrowBackIcon sx={{ width: "16px", height: "16px", marginRight: "4px" }} /> Back to User Activity
        </Link>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Header title="User Activity" subtitle={getSubTitle()} />
      </Box>
      <Box m="40px 0 0 0">
        {isLoading ? (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Box>
            <CodeMirror
              editable={false}
              theme={theme.palette.mode}
              extensions={[javascript(), EditorView.lineWrapping]}
              value={JSON.stringify(getUserEventDetail?.getUserEventDetail.data, null, 2)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

const ErrorMessage = () => (
  <Box m="20px">
    <Box display="flex" justifyContent="space-between">
      <h2>Invalid values passed as parameters.</h2>
    </Box>
  </Box>
);

export default UsersActivityDetail;
