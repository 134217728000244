import PageWrapper from "components/PageWrapper";

function AdminOnboarding() {
  return (
    <PageWrapper>
      <p>AdminOnboardingTable</p>
    </PageWrapper>
  );
}

export default AdminOnboarding;
