import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  CircularProgress,
  Button,
  useTheme,
  InputAdornment,
  IconButton
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuth } from "../../../app/authHooks";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { Eye, EyeClosed, UserScan } from "iconoir-react";

const initialValues = {
  username: "",
  password: "",
  rememberMe: "",
};

const loginSchema = yup.object().shape({
  username: yup.string().required("Username is required."),
  password: yup.string().required("Password is required."),
  rememberMe: yup.boolean(),
});

const LoginForm = () => {
  const theme = useTheme();
  const colors = theme.palette;
  const auth = useAuth();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleFormSubmit = async (values: any) => {
    setIsLoading(true);
    const signInResult = await auth.signIn(values.username, values.password);
    setIsLoading(false);
    if (!signInResult.success) {
    //   if (signInResult?.path) {
    //     console.log("Path: ", signInResult?.path);
    //     // navigate({ pathname: signInResult?.path });
    //   } else {
    //     enqueueSnackbar(`Logged In but no further action implemented ${signInResult}`, { variant: "info" });
    //   }
    // } else {
      enqueueSnackbar(signInResult.message, { variant: "error" });
    }
  };

  return (

    <Box sx={{ background: colors.primary.light, minHeight: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ marginBottom: 4 }}>
        <Box
          component="img"
          alt="ss&c-logo"
          src="../../../assets/images/ssc-hubwise-logo.svg"
          sx={{
            width: 250,
            height: "auto"
          }}
        />
      </Box>
      <Box
        sx={{
          p: 5,
          background: colors.grey[900],
          borderRadius: 4,
          boxShadow: "1px 3px 10px rgba(202, 202, 202, 0.83)",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <Typography variant="h4" sx={{ fontWeight: 700, color: colors.grey[400] }}>Admin Platform</Typography>
          <Typography variant="h5" sx={{ fontWeight: 700 }}>Sign In</Typography>
        </Box>

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={loginSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Box
                  // display="grid"
                  // gap="30px"
                  // gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    // "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 4,
                    marginTop: 5
                  }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    label="Username"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                    name="username"
                    error={!!touched.username && !!errors.username}
                    helperText={!!touched.username && errors.username}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx= {{
                          fontSize: 14
                        }}>
                          <UserScan />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    fullWidth
                    variant="outlined"
                    type={showPassword ? "text" : "password" }
                    label="Password"
                    size="small"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={!!touched.password && !!errors.password}
                    helperText={!!touched.password && errors.password}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx= {{
                          fontSize: 14,
                          ":hover": {
                            cursor: "pointer"
                          }
                        }}>
                          <IconButton sx={{
                            p:0,
                            border: 0,
                            boxShadow: 0,
                            borderRadius: 0,
                            ":hover": {
                              backgroundColor: colors.grey[900],
                              boxShadow: 0
                            }
                          }}
                          onClick={handleTogglePasswordVisibility}
                          >{ showPassword ? <Eye/> : <EyeClosed /> }</IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>

                <Box
                  sx={{
                    mt: "1rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel control={<Checkbox />} label="Remember me" />
                  <Typography variant="h5" component={Link} to="/auth/forgot-password">
                    Forgot Password?
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth
                    disabled={isLoading}
                    endIcon={isLoading ? <CircularProgress size={20} /> : null}
                  >
                    {isLoading ? "Signing In..." : "Sign In"}
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 2, gap: 1 }}>
        <Typography variant="h5">By clicking continue, you agree to our</Typography>
        <Typography variant="h5"><Typography variant="h5" component={Link} to="#" sx={{
          color: colors.grey[400],
          ":hover": {
            color: colors.primary.main
          }
        }}>
                    Terms of Service
        </Typography> and <Typography variant="h5" component={Link} to="#" sx={{
          color: colors.grey[400],
          ":hover": {
            color: colors.primary.main
          }
        }}>
                    Privacy Policy
        </Typography></Typography>
      </Box>
    </Box>
  );
};

export default LoginForm;
