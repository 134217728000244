import React from "react";
import { Amplify } from "aws-amplify";
import { Provider } from "react-redux";
import { ProvideAuth } from "./app/authHooks";
import ReactDOM from "react-dom/client";
import { CookieStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";

import awsExports from "./aws/aws-exports";
import awsGateway from "./aws/aws-gateway";

import { store } from "./app/store";

import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

const IS_LOCALHOST = Boolean(
  window.location.hostname === "localhost" ||
  window.location.hostname === "[::1]" ||
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])){3}$/
  )
);

const cookieStorage = new CookieStorage({
  path: "/",
  domain: IS_LOCALHOST ? document.location.hostname : ".hubwise.co.uk",
  expires: 365,
  sameSite: "lax",
  secure: !IS_LOCALHOST
});

Amplify.configure(Object.assign(awsExports, awsGateway));
cognitoUserPoolsTokenProvider.setKeyValueStorage(cookieStorage);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <ProvideAuth>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ProvideAuth>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
