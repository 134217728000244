/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../app/services/types.generated';

import { api } from 'app/graphqlBaseApi';
module.hot?.accept();
export type GetInvestorQueryVariables = Types.Exact<{
  internalId: Types.Scalars['String']['input'];
}>;


export type GetInvestorQuery = { __typename?: 'Query', getInvestor: { __typename?: 'GetEntityInvestorResponse', statusCode: number, success: boolean, errorMessage?: string, errorType?: string, data?: Array<{ __typename?: 'EntityInvestorResponseType', id: string, customerId: string, createdAt: string, externalId: string, internalId: string, provider: string, tenantId: string, type: string }> } };

export type UpdateInvestorMutationVariables = Types.Exact<{
  input: Types.EntityInvestorUpdate;
}>;


export type UpdateInvestorMutation = { __typename?: 'Mutation', updateInvestor: { __typename?: 'EntityInvestorUpdateRespone', statusCode: number, success: boolean, errorMessage?: string, errorType?: string } };

export type CreateInvestorMutationVariables = Types.Exact<{
  input: Types.EntityInvestorCreate;
}>;


export type CreateInvestorMutation = { __typename?: 'Mutation', createInvestor: { __typename?: 'EntityInvestorCreateRespone', statusCode: number, success: boolean, errorMessage?: string, errorType?: string } };


export const GetInvestorDocument = `
    query GetInvestor($internalId: String!) {
  getInvestor(internalId: $internalId) {
    statusCode
    success
    errorMessage
    errorType
    data {
      id
      customerId
      createdAt
      externalId
      internalId
      provider
      tenantId
      type
    }
  }
}
    `;
export const UpdateInvestorDocument = `
    mutation UpdateInvestor($input: EntityInvestorUpdate!) {
  updateInvestor(input: $input) {
    statusCode
    success
    errorMessage
    errorType
  }
}
    `;
export const CreateInvestorDocument = `
    mutation CreateInvestor($input: EntityInvestorCreate!) {
  createInvestor(input: $input) {
    statusCode
    success
    errorMessage
    errorType
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    GetInvestor: build.query<GetInvestorQuery, GetInvestorQueryVariables>({
      query: (variables) => ({ document: GetInvestorDocument, variables })
    }),
    UpdateInvestor: build.mutation<UpdateInvestorMutation, UpdateInvestorMutationVariables>({
      query: (variables) => ({ document: UpdateInvestorDocument, variables })
    }),
    CreateInvestor: build.mutation<CreateInvestorMutation, CreateInvestorMutationVariables>({
      query: (variables) => ({ document: CreateInvestorDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetInvestorQuery, useLazyGetInvestorQuery, useUpdateInvestorMutation, useCreateInvestorMutation } = injectedRtkApi;

