import React from "react";

import { GetAllEventsQuery, useGetExternalEntityMapQuery } from "./api/events.generated";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Collapse, IconButton, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";

type Event = GetAllEventsQuery["getAllEvents"]["items"][0];

export const rows: { label: string, key: keyof Event, Renderer?: React.FC<{ row: Event, displayName?: string }> }[] = [
  { label: "Created", key: "createdAt" },
  { label: "Method", key: "method" },
  { label: "UserID", key: "userId", Renderer: ({ row, displayName }) => (
    `${row.userId}${displayName ? ` (${displayName})` : ""}`
  ) },
  { label: "Path", key: "path" },
  { label: "Duration", key: "duration" },
  { label: "HTTP Status", key: "httpStatus" },
  { label: "Entity Type", key: "entityType" },
  { label: "Identifier", key: "identifier" },
];

export const RenderObject: React.FC<{ payload?: string | null | object }> = ({ payload }) => {
  const theme = useTheme();

  try {
    if (!payload) return null;
    const objectToStringify = typeof payload === "object" ? payload : JSON.parse(payload);
    return <CodeMirror
      editable={false}
      theme={theme.palette.mode}
      extensions={[javascript()]}
      value={JSON.stringify(objectToStringify, null, 2)}
    />;
  } catch (e) {
    return <CodeMirror
      editable={false}
      theme={theme.palette.mode}
      extensions={[javascript()]}
      value="Unexpected Error"
    />;
  }
};

type Metadata = { headers?: object }

const Row: React.FC<{ row: Event }> = ({ row }) => {
  const [open, setOpen] = React.useState(false);
  const { headers } = (row.metadata ? JSON.parse(row.metadata) : {}) as Metadata;
  const externalEntityMapResult = useGetExternalEntityMapQuery({
    id: `IO|${row.userId}|EMPLOYEE`
  });
  const displayName = externalEntityMapResult.data?.getExternalEntityMap?.items?.[0]?.metadata?.displayName;

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {rows.map((el) => (
          <TableCell key={el.key}>
            {el.Renderer ? <el.Renderer row={row} displayName={displayName} /> : row[el.key]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0 }} colSpan={rows.length + 1}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{
              paddingX: "5px",
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "5px",
              maxHeight: "500px",
              overflowY: "scroll",
            }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", overflow: "auto" }}>
                <Typography variant="h4">Customer</Typography>
                {row.customer ? <RenderObject payload={JSON.stringify(row.customer)} /> : "No data"}
                <Typography variant="h4">UserID</Typography>
                {row.userId
                  ? <RenderObject
                    payload={JSON.stringify(`${row.userId}${displayName ? ` (${displayName})` : ""}`)}
                  />
                  : "No data"}
                <Typography variant="h4">EventID</Typography>
                {row.eventId ? <RenderObject payload={JSON.stringify(row.eventId)} /> : "No data"}
                <Typography variant="h4">Headers</Typography>
                {headers ? <RenderObject payload={JSON.stringify(headers)} /> : "No data"}
              </Box>
              <Box sx={{ overflow: "auto" }}>
                <Typography variant="h4">Request</Typography>
                {row.body ? <RenderObject payload={row.body} /> : "No data"}
              </Box>
              <Box sx={{ overflow: "auto" }}>
                <Typography variant="h4">Response</Typography>
                {row.response ? <RenderObject payload={row.response} /> : "No data"}
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Row;
