import { Box, useTheme } from "@mui/material";
import LoginForm from "../forms/loginForm";

const LoginFormWrapper = () => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <LoginForm />
  );
};

export default LoginFormWrapper;
