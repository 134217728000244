import { api } from "./intelliflo-integration.generated";
import {
  DefinitionsFromApi,
  TagTypesFromApi
} from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { ExternalEntityMap, IntellifloIntegrationLegalType } from "app/services/types.generated";
import * as yup from "yup";

export type * from "./intelliflo-integration.generated";

export namespace IntegrationService {
  export const IntellifloTenant = yup.object().shape({
    id: yup.string().optional(),
    type: yup.string().required("Integration type is required."),
    externalId: yup.string().required("Intelliflo tenant id is required."),
    internalId: yup.string().required("Hubwise customer cdentifier is required."),
    providerAppId: yup.string().required("Provider app id is required."),
    bulkValAppId: yup.string().required("Bulk valuation app is required."),
    metadata: yup.object({
      displayName: yup.string().required("Hubwise Customer Name required.")
    })
  });

  export const IntellifloIntegration = yup.object({
    id: yup.string().optional(),
    settings: yup.object({
      allowClientImport: yup.boolean().optional(),
      allowClientRelationshipImport: yup.boolean().optional(),
      allowDocumentUpload: yup.boolean().optional(),
      allowWealthLinkBulkValuation: yup.boolean().optional(),
      type: yup.string().required("Integration Type is required"),
      wealthLinkIncomeStatement: yup.object({
        enabled: yup.boolean(),
        groupId: yup.string().optional()
      }),
      wealthLinkModelSync: yup.object({
        enabled: yup.boolean()
      })
    })
  });

  export const TenantLegalEntities = yup.object({
    id: yup.string().optional(),
    legalEntities: yup.array(yup.object(({
      customerId: yup.string().required("Customer Id is required"),
      groupId: yup.string().required("Group Id is required"),
      fcaRefNo: yup.string().required("FCA Reference is required")
    })))
  });

  export const IntellifloIntegrationLegalEntities = yup.array(
    yup.object({
      integrationSettings: yup.object({
        id: yup.string().optional(),
        settings: yup.object({
          allowClientImport: yup.boolean().optional(),
          allowClientRelationshipImport: yup.boolean().optional(),
          allowDocumentUpload: yup.boolean().optional(),
          allowWealthLinkBulkValuation: yup.boolean().optional(),
          wealthLinkIncomeStatement: yup.object({
            enabled: yup.boolean(),
          }),
          wealthLinkModelSync: yup.object({
            enabled: yup.boolean()
          })
        })
      }),
      legalEntity: yup.object({
        customerId: yup.string().required("Customer Id is required"),
        groupId: yup.string().required("Group Id is required"),
        fcaRefNo: yup.string().required("FCA Reference is required")
      })

    })
  );
  export const CombinedSchema = yup.object({
    intellifloIntegrationLegalEntitiesInput: IntellifloIntegrationLegalEntities,
    intellifloTenantInput: IntellifloTenant,
  });

  export type CombinedSchemaType = {
    intellifloTenantInput: ExternalEntityMap,
    intellifloIntegrationLegalEntitiesInput: Array<IntellifloIntegrationLegalType>,
  };
  // intellifloIntegrationInput: IntellifloIntegrationType,
  // tenantLegalEntitiesInput: TenantLegalEntitiesType,

  export enum IntegrationType {
    GATEWAY = "gateway",
    PORTAL = "portal"
  }

  const generatedApi = api.enhanceEndpoints({ addTagTypes: ["IntellifloTenants", "TenantIntegrationSettings", "TenantLegalEntities", "IntegrationAndTenantLegalEntities"] });

  type Definitions = DefinitionsFromApi<typeof generatedApi>
  type TagTypes = TagTypesFromApi<typeof generatedApi>

  export const enhancedApi = generatedApi.enhanceEndpoints<TagTypes, Definitions>({
    endpoints: {
      GetExternalEntityMapAll(endpoint) {
        endpoint.providesTags = ["IntellifloTenants"];
      },
      GetTenantIntegrationSettings(endpoint) {
        endpoint.providesTags = ["TenantIntegrationSettings"];
      },
      GetTenantLegalEntities(endpoint) {
        endpoint.providesTags = ["TenantLegalEntities"];
      },
      GetIntegrationAndTenantLegalEntities(endpoint) {
        endpoint.providesTags = ["IntegrationAndTenantLegalEntities"];
      },
      CreateIntellifloTenant(endpoint) {
        endpoint.invalidatesTags = ["TenantIntegrationSettings", "IntellifloTenants", "TenantLegalEntities", "IntegrationAndTenantLegalEntities"];
      },
      UpdateIntellifloTenant(endpoint) {
        endpoint.invalidatesTags = ["TenantIntegrationSettings", "IntellifloTenants", "TenantLegalEntities", "IntegrationAndTenantLegalEntities"];
      }
    }
  });

  export const {
    useGetAllIntellifloTenantQuery,
    useGetIntegrationAndTenantLegalEntitiesQuery,
    useGetExternalEntityMapAllQuery,
    useGetTenantIntegrationSettingsQuery,
    useGetTenantLegalEntitiesQuery,
    useCreateIntellifloTenantMutation,
    useUpdateIntellifloTenantMutation
  } = enhancedApi;

}
