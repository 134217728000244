export type UserEvent = {
    eventId: number,
    userId: number,
    firstName: string,
    username: string,
    userType: string,
    eventType: string,
    eventTimestamp: string,
    ipAddress?: string,
    deviceInfo?: string,
    lastSuccessfulLoginAt?: string,
    lastFailedLoginAt?: string
}

export const USER_TYPES: { label: string, value: string }[] = [
  { label: "Adviser", value: "adviser" },
  { label: "Investor", value: "investor" }
];

export const DATE_DIRECTIONS: string[] = [
  "Before", "After"
];

export const EVENT_TYPES: { label: string, value: string }[] = [
  { label: "Login Successful", value: "LoginSuccessful" },
  { label: "Login Failed", value: "LoginFailed" },
  { label: "Sign Out", value: "SignOut" },
  { label: "Reset Password", value: "ResetPassword" }
];
