/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../app/services/types.generated';

import { api } from 'app/graphqlBaseApi';
module.hot?.accept();
export type GetUserEventDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  userType: Types.Scalars['String']['input'];
}>;


export type GetUserEventDetailQuery = { __typename?: 'Query', getUserEventDetail: { __typename?: 'GetUserEventDetailResponse', status: number, data?: { __typename?: 'GetUserEventDetail', additionalData: string, userLastFailedLoginAt?: string, userLastSuccessfulLoginAt?: string, event: { __typename?: 'UserEventData', eventId: number, eventType: string, firstName: string, username: string, userId: number, eventTimestamp: string, ipAddress?: string, deviceInfo?: string } } } };

export type GetUserEventsQueryVariables = Types.Exact<{
  getUserEventsFilter: Types.GetUserEventsFilterInput;
}>;


export type GetUserEventsQuery = { __typename?: 'Query', getUserEvents: { __typename?: 'GetUserEventsResponse', details?: string, message?: string, pageIndex?: number, pageSize?: number, status?: number, total?: number, data?: Array<{ __typename?: 'UserEventData', deviceInfo?: string, eventId: number, eventTimestamp: string, eventType: string, firstName: string, ipAddress?: string, lastFailedLoginAt?: string, lastSuccessfulLoginAt?: string, userId: number, username: string }> } };


export const GetUserEventDetailDocument = `
    query GetUserEventDetail($id: Int!, $userType: String!) {
  getUserEventDetail(id: $id, userType: $userType) {
    data {
      additionalData
      event {
        eventId
        eventType
        firstName
        username
        userId
        eventTimestamp
        ipAddress
        deviceInfo
      }
      userLastFailedLoginAt
      userLastSuccessfulLoginAt
    }
    status
  }
}
    `;
export const GetUserEventsDocument = `
    query GetUserEvents($getUserEventsFilter: GetUserEventsFilterInput!) {
  getUserEvents(getUserEventsFilter: $getUserEventsFilter) {
    data {
      deviceInfo
      eventId
      eventTimestamp
      eventType
      firstName
      ipAddress
      lastFailedLoginAt
      lastSuccessfulLoginAt
      userId
      username
    }
    details
    message
    pageIndex
    pageSize
    status
    total
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    GetUserEventDetail: build.query<GetUserEventDetailQuery, GetUserEventDetailQueryVariables>({
      query: (variables) => ({ document: GetUserEventDetailDocument, variables })
    }),
    GetUserEvents: build.query<GetUserEventsQuery, GetUserEventsQueryVariables>({
      query: (variables) => ({ document: GetUserEventsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetUserEventDetailQuery, useLazyGetUserEventDetailQuery, useGetUserEventsQuery, useLazyGetUserEventsQuery } = injectedRtkApi;

