import { useEffect, useState } from "react";

import awsgateway from "aws/aws-gateway";
import axios from "axios";

export interface ChangelogItem {
    jira: string;
    assignee: string;
    jiraType: string;
    reporter: string;
    releaseNotes: string;
    environment: string;
}

export interface ChangelogDetails {
    item: ChangelogItem;
    details: string[];
}

export interface ChangelogCategory {
    [jiraReference: string]: ChangelogDetails;
}

export interface ChangelogData {
    [date: string]: {
        Story?: ChangelogCategory;
        Bug?: ChangelogCategory;
        Task?: ChangelogCategory;
    };
}

const limit = 100;
const changelogApiConfig = awsgateway.API.endpoints.find((api) => api.name === "ChangelogAPi");

const endpoint = changelogApiConfig?.endpoint;

function useChangeLog(page: number, searchString: string = "") {
  const [changelogData, setChangelogData] = useState<ChangelogData>({});
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);

        const encodedKey = encodeURIComponent(JSON.stringify(lastEvaluatedKey));

        let url;
        if (searchString) {
          url = `${endpoint}&searchString=${searchString}`;
        } else if (changelogData && lastEvaluatedKey) {
          url = `${endpoint}&limit=${limit}&lastEvaluatedKey=${encodedKey}`;
        } else if (Object.keys(changelogData).length === 0) {
          url = `${endpoint}&limit=${limit}`;
        }

        if (url) {
          const response = await axios.get(url);
          const changelog = response.data.changelog;

          setChangelogData((prevChangelog) => {
            return { ...prevChangelog, ...changelog };
          });

          const newLastEvaluatedKey = response.data.lastEvaluatedKey;
          if (newLastEvaluatedKey) {
            setHasMore(true);
            setLastEvaluatedKey(newLastEvaluatedKey);
          } else {
            setLastEvaluatedKey(null);
            setHasMore(false);
          }
        }

        setLoading(false);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setErrorMessage(
            error.response?.data?.message || "An error occurred while fetching the changelog."
          );
        } else {
          setErrorMessage("An unexpected error occurred.");
        }
      } finally {
        setLoading(false);
      }

    })();
  }, [page, searchString]);

  return { changelogData, hasMore, loading, errorMessage, setChangelogData };
}

export default useChangeLog;
