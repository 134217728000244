import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { Divider, Typography } from "@mui/material";

export type CardProps = {
  id?: string;
  className?: string;
  title?: string;
  cardContent?: React.ReactNode;
  footerActions?: React.ReactNode;
  hasMargin?: boolean;
  elevation?: number;
};

export const CardComponent : React.FC<CardProps> = ({ title, cardContent, footerActions }) => {

  return (
    <Card >
      <CardHeader
        title={
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            {title}
          </Typography>
        }
      />
      <Divider />
      <CardContent sx={{ paddingX: 6 }}>
        {cardContent}
      </CardContent>
      {footerActions && (
        <>
          <Divider />
          <CardActions>
            {footerActions}
          </CardActions>
        </>
      )}
    </Card>
  );
};
