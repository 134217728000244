/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../app/services/types.generated';

import { api } from 'app/graphqlBaseApi';
module.hot?.accept();
export type GetJobItemsQueryVariables = Types.Exact<{
  jobName: Types.Scalars['String']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  lastEvaluatedKey?: Types.InputMaybe<Types.JobLastEvaluatedKeyInput>;
}>;


export type GetJobItemsQuery = { __typename?: 'Query', getJobItems: { __typename?: 'GetjobItemsResponse', jobName: string, status: number, success?: boolean, totalItems: number, lastEvaluatedKey?: { __typename?: 'JobLastEvaluatedKeyInputResponse', id?: string, type?: string, createdAt?: string }, documentProcessTableData?: Array<{ __typename?: 'DocumentProcessJobType', id?: string, createdAt?: string, documentId?: string, failed?: boolean, failedAt?: string, failedReason?: string, generatedId?: boolean, parentJobId?: string, synchronised?: boolean, type?: string, updatedAt?: string, uploaded?: boolean }>, bulkValuationTableData?: Array<{ __typename?: 'BulkValuationJobType', id?: string, createdAt?: string, customerId?: string, errorMessage?: string, externalId?: string, externalStatus?: string, internalId?: string, itemsFailed?: number, itemsImported?: number, provider?: string, status?: string, tenantId?: string, type?: string }>, dataSyncTableData?: Array<{ __typename?: 'DataSyncJobType', id?: string, createdDate?: string, customer?: string, request?: string, retryAttempt?: number, status?: string, statusReason?: string, tenant?: string, type?: string, updatedTime?: string }> } };

export type GetJobItemByIdQueryVariables = Types.Exact<{
  jobName: Types.Scalars['String']['input'];
  id: Types.Scalars['String']['input'];
}>;


export type GetJobItemByIdQuery = { __typename?: 'Query', getJobItemById: { __typename?: 'GetJobItemByIdResponse', statusCode: number, success: boolean, bulkValuationData?: { __typename?: 'BulkValuationJobType', id?: string, createdAt?: string, customerId?: string, errorMessage?: string, externalId?: string, externalStatus?: string, internalId?: string, itemsFailed?: number, itemsImported?: number, manualExecution?: boolean, provider?: string, status?: string, tenantId?: string, type?: string, data?: string }, documentProcessData?: { __typename?: 'DocumentProcessJobType', id?: string, createdAt?: string, documentId?: string, failed?: boolean, failedAt?: string, failedReason?: string, generatedId?: boolean, parentJobId?: string, synchronised?: boolean, type?: string, updatedAt?: string, uploaded?: boolean, metadata?: string }, dataSyncData?: { __typename?: 'DataSyncJobType', id?: string, createdDate?: string, customer?: string, retryAttempt?: number, status?: string, statusReason?: string, tenant?: string, type?: string, updatedTime?: string, request?: string } } };


export const GetJobItemsDocument = `
    query GetJobItems($jobName: String!, $limit: Int, $lastEvaluatedKey: JobLastEvaluatedKeyInput) {
  getJobItems(
    jobName: $jobName
    limit: $limit
    lastEvaluatedKey: $lastEvaluatedKey
  ) {
    jobName
    status
    success
    lastEvaluatedKey {
      id
      type
      createdAt
    }
    totalItems
    documentProcessTableData {
      id
      createdAt
      documentId
      failed
      failedAt
      failedReason
      generatedId
      parentJobId
      synchronised
      type
      updatedAt
      uploaded
    }
    bulkValuationTableData {
      id
      createdAt
      customerId
      errorMessage
      externalId
      externalStatus
      internalId
      itemsFailed
      itemsImported
      provider
      status
      tenantId
      type
    }
    dataSyncTableData {
      id
      createdDate
      customer
      request
      retryAttempt
      status
      statusReason
      tenant
      type
      updatedTime
    }
  }
}
    `;
export const GetJobItemByIdDocument = `
    query GetJobItemById($jobName: String!, $id: String!) {
  getJobItemById(jobName: $jobName, id: $id) {
    statusCode
    success
    bulkValuationData {
      id
      createdAt
      customerId
      errorMessage
      externalId
      externalStatus
      internalId
      itemsFailed
      itemsImported
      manualExecution
      provider
      status
      tenantId
      provider
      type
      data
    }
    documentProcessData {
      id
      createdAt
      documentId
      failed
      failedAt
      failedReason
      generatedId
      parentJobId
      synchronised
      type
      updatedAt
      uploaded
      metadata
    }
    dataSyncData {
      id
      createdDate
      customer
      retryAttempt
      status
      statusReason
      tenant
      type
      updatedTime
      request
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    GetJobItems: build.query<GetJobItemsQuery, GetJobItemsQueryVariables>({
      query: (variables) => ({ document: GetJobItemsDocument, variables })
    }),
    GetJobItemById: build.query<GetJobItemByIdQuery, GetJobItemByIdQueryVariables>({
      query: (variables) => ({ document: GetJobItemByIdDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetJobItemsQuery, useLazyGetJobItemsQuery, useGetJobItemByIdQuery, useLazyGetJobItemByIdQuery } = injectedRtkApi;

