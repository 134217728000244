import { Box } from "@mui/material";
import UsersActivityTable from "./user-activity-table/index";

function UsersActivity() {
  return (
    <Box sx={{ paddingBottom: "64px" }}>
      <UsersActivityTable />
    </Box>
  );
}

export default UsersActivity;
