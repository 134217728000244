import { Box, CircularProgress, useTheme } from "@mui/material";
import { useParams } from "react-router";
import Header from "components/Header";
import { GetJobItemByIdQuery, useGetJobItemByIdQuery } from "../api/job-detail.generated";
import CodeMirror, { EditorView } from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";

type JobData = { provider?: string; type?: string; accountIds?: string; data?: string; }
    | { request?: string; }
    | { metadata?: string; };

const JobItemDetail = () => {
  const theme = useTheme();
  const colors = theme.palette;
  const { name, id } = useParams<{ name: string; id: string }>();
  const { title, subTitle } = getTitleAndSubtitle(name, id);

  if (!name || !id) {
    return errorMessage();
  }

  const { data: getJobItemById, isLoading, isError } = useGetJobItemByIdQuery({ jobName: name, id: id });

  const data = getData(getJobItemById, name);

  if (isError) {
    return errorMessage();
  }

  return (
    <Box m="20px">
      <Header title={title} subtitle={subTitle} />
      <Box m="40px 0 0 0">
        {(isLoading || !data) ? (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <Box>

            <CodeMirror
              editable={false}
              theme={theme.palette.mode}
              extensions={[javascript(), EditorView.lineWrapping]}
              value={JSON.stringify(data, null, 2)}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

const getTitleAndSubtitle = (name: string | undefined, id: string | undefined) => {
  const title: string | undefined = getJobName(name);
  const subTitle = id || "";

  return { title, subTitle };
};

const getJobName = (name: string | undefined) => {
  switch (name) {
    case "bulk-valuation":
      return "Bulk Valuation";
    case "data-sync":
      return "Data Sync";
    case "documents":
      return "Documents";
    default:
      return "Job";
  }
};

const getData = (getItemByIdData: GetJobItemByIdQuery | undefined, jobName: string) => {
  const bulkData = getItemByIdData?.getJobItemById.bulkValuationData;
  const dataSync = getItemByIdData?.getJobItemById.dataSyncData;
  const documents = getItemByIdData?.getJobItemById.documentProcessData;
  switch (jobName) {
    case "bulk-valuation":
      return bulkData ? bulkData : {};
    case "data-sync":
      return dataSync ? dataSync : {};
    case "documents":
      return documents ? documents : {};
    default:
      return {};
  }
};

const errorMessage = () => (
  <Box
    sx={{
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <CircularProgress color="secondary" />
  </Box>
);

export default JobItemDetail;
