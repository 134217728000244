/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../app/services/types.generated';

import { api } from 'app/graphqlBaseApi';
module.hot?.accept();
export type GetStateMachinesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStateMachinesQuery = { __typename?: 'Query', getStateMachines: { __typename: 'StateMachinesResponse', items: Array<{ __typename: 'StateMachine', name: string, type: Types.StateMachineType, creationDate?: any, id: string }> } };

export type GetStateMachineSummaryQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetStateMachineSummaryQuery = { __typename?: 'Query', getStateMachineSummary: { __typename: 'StateMachineSummary', id: string, definition: string, name: string, status: string, executions?: Array<{ __typename: 'StateMachineExecutions', id: string, status: Types.ExecutionStatus, name: string, startDate: any, stopDate?: any }> } };

export type GetStateMachineExecutionSummaryQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetStateMachineExecutionSummaryQuery = { __typename?: 'Query', getStateMachineExecutionSummary: { __typename: 'StateMachineExecutionSummary', id: string, name: string, status: Types.ExecutionStatus, startDate?: any, stopDate?: any, output?: string, input?: string, events?: Array<{ __typename: 'StateMachineExecutionEvent', id: number, timestamp?: any, type: string, previousEventId?: number, detail?: any }> } };

export type StartExecutionMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  input?: Types.InputMaybe<Types.Scalars['AWSJSON']['input']>;
}>;


export type StartExecutionMutation = { __typename?: 'Mutation', startExecution?: { __typename?: 'StartExecutionResponse', id: string, executionId: string, startDate: any } };


export const GetStateMachinesDocument = `
    query GetStateMachines {
  getStateMachines {
    items {
      name
      type
      creationDate
      id
      __typename
    }
    __typename
  }
}
    `;
export const GetStateMachineSummaryDocument = `
    query GetStateMachineSummary($id: String!) {
  getStateMachineSummary(id: $id) {
    id
    definition
    name
    status
    executions {
      id
      status
      name
      startDate
      stopDate
      __typename
    }
    __typename
  }
}
    `;
export const GetStateMachineExecutionSummaryDocument = `
    query GetStateMachineExecutionSummary($id: String!) {
  getStateMachineExecutionSummary(id: $id) {
    id
    name
    status
    startDate
    stopDate
    output
    input
    events {
      id
      timestamp
      type
      previousEventId
      detail
      __typename
    }
    __typename
  }
}
    `;
export const StartExecutionDocument = `
    mutation StartExecution($id: String!, $input: AWSJSON) {
  startExecution(id: $id, input: $input) {
    id
    executionId
    startDate
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    GetStateMachines: build.query<GetStateMachinesQuery, GetStateMachinesQueryVariables | void>({
      query: (variables) => ({ document: GetStateMachinesDocument, variables })
    }),
    GetStateMachineSummary: build.query<GetStateMachineSummaryQuery, GetStateMachineSummaryQueryVariables>({
      query: (variables) => ({ document: GetStateMachineSummaryDocument, variables })
    }),
    GetStateMachineExecutionSummary: build.query<GetStateMachineExecutionSummaryQuery, GetStateMachineExecutionSummaryQueryVariables>({
      query: (variables) => ({ document: GetStateMachineExecutionSummaryDocument, variables })
    }),
    StartExecution: build.mutation<StartExecutionMutation, StartExecutionMutationVariables>({
      query: (variables) => ({ document: StartExecutionDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetStateMachinesQuery, useLazyGetStateMachinesQuery, useGetStateMachineSummaryQuery, useLazyGetStateMachineSummaryQuery, useGetStateMachineExecutionSummaryQuery, useLazyGetStateMachineExecutionSummaryQuery, useStartExecutionMutation } = injectedRtkApi;

