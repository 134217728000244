/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../app/services/types.generated';

import { api } from 'app/graphqlBaseApi';
module.hot?.accept();
export type GetIpSetQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.GetIpSetInput>;
}>;


export type GetIpSetQuery = { __typename?: 'Query', getIpSet: { __typename?: 'IpSet', ipList: Array<{ __typename?: 'List', id?: string, isActive: boolean, activeMessage?: string, changedAt?: string, createdAt?: string, ip: string, owner?: string, comment?: string }> } };

export type GetIpGroupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetIpGroupsQuery = { __typename?: 'Query', getIpSetGroups: { __typename?: 'GetIpSetGroups', items: Array<{ __typename?: 'GetIpSetGroupsItem', createdAt: string, isActive: string, dbKey: string, name: string, type: string, ipSet: { __typename?: 'GetIpSetGroupsIpSet', id: string, name: string } }> } };

export type UpdateIpSetMutationVariables = Types.Exact<{
  input: Types.IpSetInput;
}>;


export type UpdateIpSetMutation = { __typename?: 'Mutation', updateIpSet?: boolean };


export const GetIpSetDocument = `
    query GetIpSet($input: getIpSetInput) {
  getIpSet(input: $input) {
    ipList {
      id
      isActive
      activeMessage
      changedAt
      createdAt
      ip
      owner
      comment
    }
  }
}
    `;
export const GetIpGroupsDocument = `
    query GetIpGroups {
  getIpSetGroups {
    items {
      createdAt
      ipSet {
        id
        name
      }
      isActive
      dbKey
      name
      type
    }
  }
}
    `;
export const UpdateIpSetDocument = `
    mutation UpdateIpSet($input: IpSetInput!) {
  updateIpSet(data: $input)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    GetIpSet: build.query<GetIpSetQuery, GetIpSetQueryVariables | void>({
      query: (variables) => ({ document: GetIpSetDocument, variables })
    }),
    GetIpGroups: build.query<GetIpGroupsQuery, GetIpGroupsQueryVariables | void>({
      query: (variables) => ({ document: GetIpGroupsDocument, variables })
    }),
    UpdateIpSet: build.mutation<UpdateIpSetMutation, UpdateIpSetMutationVariables>({
      query: (variables) => ({ document: UpdateIpSetDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetIpSetQuery, useLazyGetIpSetQuery, useGetIpGroupsQuery, useLazyGetIpGroupsQuery, useUpdateIpSetMutation } = injectedRtkApi;

