import { forwardRef } from "react";
import {
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { UserEvent } from "../../model";

interface TableRowProps {
  row: UserEvent;
}

const UsersActivityRow = forwardRef<HTMLTableRowElement, TableRowProps>((props) => {
  const navigate = useNavigate();
  const row = props.row;

  return (
    <>
      <TableRow sx={{ borderBottom: "none", "& > *": { borderBottom: "unset" } }}>
        <TableCell>{row?.eventType}</TableCell>
        <TableCell>{row?.eventTimestamp}</TableCell>
        <TableCell>{row?.ipAddress}</TableCell>
        <TableCell>{row?.deviceInfo}</TableCell>
        <TableCell>{row?.lastSuccessfulLoginAt}</TableCell>
        <TableCell>{row?.lastFailedLoginAt ? row?.lastFailedLoginAt : "NA"}</TableCell>
        <TableCell>
          <Tooltip title="Show Data">
            <IconButton
              size="small"
              onClick={() => navigate(`${row.eventId}`, { state: { username: row.username } } ) }>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    </>
  );
});

export default UsersActivityRow;
