import { Navigate, Route, Routes } from "react-router-dom";

import Jobs from "./core/scenes/jobs";
import IpSet from "./core/scenes/ipSet";
import Events from "./core/scenes/events";
import Dashboard from "./core/scenes/dashboard";
import DocumentJobs from "./core/scenes/documentJobs";
import LoginWrapper from "./core/scenes/auth/LoginWrapper";
import VerifyWrapper from "./core/scenes/auth/VerifyWrapper";
import StateMachinesList from "./core/scenes/stateMachines/list";
import StateMachineSummary from "./core/scenes/stateMachines/summary";
import StateMachineExecutionSummary from "./core/scenes/stateMachines/executionSummary";
import Changelog from "core/scenes/changelog";
import auth from "./app/authHooks";
import Integrations from "core/scenes/intelliflo-integrations";
import AddIntegration from "core/scenes/intelliflo-integrations/add-intelliflo-tenant/AddIntellifloTenant";
import IOConfig from "core/scenes/io-config";
import AddEditIOConfig from "core/scenes/io-config/add-edit-config";
import OnboardingUpload from "core/scenes/onboarding";
import InvestorUsers from "core/scenes/investor-users";
import AddEditInvestorUsers from "core/scenes/investor-users/add-edit-investor-users";
import AdviserUsers from "core/scenes/advisor-users";
import AddEditAdviserUsers from "core/scenes/advisor-users/add-edit-adviser-users";
import JobDetail from "core/scenes/jobdetail";
import JobItemDetail from "core/scenes/jobdetail/job-item-detail";
import EntityMappingScreen from "core/scenes/entity-mapping";
import UsersActivity from "core/scenes/user-activity";
import UsersActivityDetail from "core/scenes/user-activity/user-activity-detail";
import { hasPermission, Permission } from "./core/utils/roles";
import AdminOnboarding from "core/scenes/admin-onboarding";

const ProtectedWrapper = ({ children, permission }: { children: JSX.Element, permission: Permission }) => {
  const { isAuthenticated, attributes } = auth.useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" replace />;
  }

  if (!hasPermission(attributes, permission)) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const AuthWrapper = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = auth.useAuth();
  return isAuthenticated ? <Navigate to="/" replace /> : children;
};

const Router = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedWrapper permission="view:dashboard">
            <Dashboard />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/changelog"
        element={
          <ProtectedWrapper permission="view:changelog">
            <Changelog />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/state-machines"
        element={
          <ProtectedWrapper permission="view:machines">
            <StateMachinesList />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/state-machines/:id"
        element={
          <ProtectedWrapper permission="view:machines">
            <StateMachineSummary />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/state-machines/:machineId/execution/:id"
        element={
          <ProtectedWrapper permission="view:machines">
            <StateMachineExecutionSummary />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/jobs"
        element={
          <ProtectedWrapper permission="view:jobs">
            <Jobs />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/jobs/:name"
        element={
          <ProtectedWrapper permission="view:jobs">
            <JobDetail />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/jobs/:name/:id"
        element={
          <ProtectedWrapper permission="view:jobs">
            <JobItemDetail />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/events"
        element={
          <ProtectedWrapper permission="view:events">
            <Events />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/ip-set"
        element={
          <ProtectedWrapper permission="view:ip-whitelists">
            <IpSet />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/integrations"
        element={
          <ProtectedWrapper permission="view:io-integrations">
            <Integrations />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/integrations/new"
        element={
          <ProtectedWrapper permission="create:io-integrations">
            <AddIntegration />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/integrations/:id"
        element={
          <ProtectedWrapper permission="view:io-integrations">
            <AddIntegration />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/io-config"
        element={
          <ProtectedWrapper permission="view:io-app-config">
            <IOConfig />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/io-config/add-edit"
        element={
          <ProtectedWrapper permission="create:io-app-config">
            <AddEditIOConfig />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/io-config/add-edit/:id"
        element={
          <ProtectedWrapper permission="view:io-app-config">
            <AddEditIOConfig />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/onboarding"
        element={
          <ProtectedWrapper permission="view:portal-onboarding">
            <OnboardingUpload />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/onboarding/csv-to-json"
        element={
          <ProtectedWrapper permission="view:portal-onboarding">
            <OnboardingUpload />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/investor-users"
        element={
          <ProtectedWrapper permission="view:invester-onboarding">
            <InvestorUsers />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/investor-users/add-edit"
        element={
          <ProtectedWrapper permission="create:invester-onboarding">
            <AddEditInvestorUsers />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/investor-users/add-edit/:id"
        element={
          <ProtectedWrapper permission="view:invester-onboarding">
            <AddEditInvestorUsers />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/investor-users/user-activity/:userId"
        element={
          <ProtectedWrapper permission="view:invester-onboarding">
            <UsersActivity />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/investor-users/user-activity/:userId/:eventId"
        element={
          <ProtectedWrapper permission="view:invester-onboarding">
            <UsersActivityDetail />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/adviser-users"
        element={
          <ProtectedWrapper permission="view:advisor-onboarding">
            <AdviserUsers />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/adviser-users/add-edit"
        element={
          <ProtectedWrapper permission="create:advisor-onboarding">
            <AddEditAdviserUsers />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/adviser-users/add-edit/:id"
        element={
          <ProtectedWrapper permission="view:advisor-onboarding">
            <AddEditAdviserUsers />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/adviser-users/user-activity/:userId"
        element={
          <ProtectedWrapper permission="view:advisor-onboarding">
            <UsersActivity />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/adviser-users/user-activity/:userId/:eventId"
        element={
          <ProtectedWrapper permission="view:advisor-onboarding">
            <UsersActivity />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/entity-mapping"
        element={
          <ProtectedWrapper permission="view:entity-mapping">
            <EntityMappingScreen />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/admin-users"
        element={
          <ProtectedWrapper permission="view:admin-onboarding">
            <AdminOnboarding />
          </ProtectedWrapper>
        }
      />
      <Route
        path="/auth/login"
        element={
          <AuthWrapper>
            <LoginWrapper />
          </AuthWrapper>
        }
      />
      <Route path="/auth/verify" element={<VerifyWrapper />} />
    </Routes>
  );
};

export default Router;
