/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../app/services/types.generated';

import { api } from 'app/graphqlBaseApi';
module.hot?.accept();
export type GetRequestsCountQueryVariables = Types.Exact<{
  time?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetRequestsCountQuery = { __typename?: 'Query', getRequestsCount: { __typename?: 'RequestCountResult', totalItems?: number, successCount?: number, badGatewayCount?: number, groupedResults?: Array<{ __typename?: 'GroupedResult', dateOrHour?: string, successCount?: number, badGatewayCount?: number, totalItems?: number }> } };


export const GetRequestsCountDocument = `
    query GetRequestsCount($time: String) {
  getRequestsCount(time: $time) {
    totalItems
    successCount
    badGatewayCount
    groupedResults {
      dateOrHour
      successCount
      badGatewayCount
      totalItems
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    GetRequestsCount: build.query<GetRequestsCountQuery, GetRequestsCountQueryVariables | void>({
      query: (variables) => ({ document: GetRequestsCountDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetRequestsCountQuery, useLazyGetRequestsCountQuery } = injectedRtkApi;

