/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../app/services/types.generated';

import { api } from 'app/graphqlBaseApi';
module.hot?.accept();
export type GetAllEventsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.EventFilterInput>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  LastEvaluatedKey?: Types.InputMaybe<Types.LastEvaluatedKeyInput>;
  month?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ascending?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetAllEventsQuery = { __typename?: 'Query', getAllEvents: { __typename: 'EventResult', scannedCount?: number, items: Array<{ __typename: 'Event', path: string, method: string, entityType: Types.EntityType, identifier: string, eventId: string, customer?: string, userId?: string, httpStatus?: number, duration?: number, body?: any, metadata?: any, response?: any, gateway: string, createdAt: any }>, LastEvaluatedKey?: { __typename?: 'LastEvaluatedKey', PK: string, SK: string } } };

export type GetExternalEntityMapQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetExternalEntityMapQuery = { __typename?: 'Query', getExternalEntityMap: { __typename?: 'ExternalEntityMapResult', items: Array<{ __typename?: 'ExternalEntityMap', metadata: { __typename?: 'Metadata', displayName?: string } }> } };


export const GetAllEventsDocument = `
    query GetAllEvents($filter: EventFilterInput, $limit: Int, $LastEvaluatedKey: LastEvaluatedKeyInput, $month: String, $ascending: Boolean) {
  getAllEvents(
    month: $month
    ascending: $ascending
    filter: $filter
    limit: $limit
    LastEvaluatedKey: $LastEvaluatedKey
  ) {
    items {
      path
      method
      entityType
      identifier
      eventId
      customer
      userId
      httpStatus
      duration
      body
      metadata
      response
      gateway
      createdAt
      __typename
    }
    LastEvaluatedKey {
      PK
      SK
    }
    scannedCount
    __typename
  }
}
    `;
export const GetExternalEntityMapDocument = `
    query GetExternalEntityMap($id: String!) {
  getExternalEntityMap(id: $id) {
    items {
      metadata {
        displayName
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    GetAllEvents: build.query<GetAllEventsQuery, GetAllEventsQueryVariables | void>({
      query: (variables) => ({ document: GetAllEventsDocument, variables })
    }),
    GetExternalEntityMap: build.query<GetExternalEntityMapQuery, GetExternalEntityMapQueryVariables>({
      query: (variables) => ({ document: GetExternalEntityMapDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetAllEventsQuery, useLazyGetAllEventsQuery, useGetExternalEntityMapQuery, useLazyGetExternalEntityMapQuery } = injectedRtkApi;

