import React from "react";

import {
  Button,
  TableCell,
  TableRow
} from "@mui/material";

import { StateMachine } from "../../../app/services/types.generated";
import { useNavigate } from "react-router";

const Row: React.FC<{ row: StateMachine }> = ({ row }) => {

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <TableRow
        sx={{ borderBottom: "none", "& > *": { borderBottom: "unset" } }}
      >
        <TableCell>{row.type}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.id}</TableCell>
        <TableCell>{`${row.creationDate}`}</TableCell>
        <TableCell>
          <Button
            onClick={() => navigate("/state-machines/" + row.id)}
            variant="outlined"
          >
            View
          </Button>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Row;
