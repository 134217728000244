import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import Header from "../../../../components/Header";
import TenantRow from "./IntellifloTenantRow";
import { ReactNode, useCallback, useRef, useState } from "react";
import Filter, { InputValues } from "./filter";
import useIntellifloTenant from "../hooks/useIntellifloTenant";
import { generatePath, useNavigate } from "react-router";
import { Paths } from "components/utils/routes";
import { hasPermission, Permission } from "core/utils/roles";
import { useAuth } from "app/authHooks";


const TablePlaceholder: React.FC<{ children: ReactNode, colSpan: number }> = ({ children, colSpan }) => (
  <TableRow>
    <TableCell colSpan={colSpan} align="center">
      {children}
    </TableCell>
  </TableRow>
);

function TenantTable() {
  const { attributes } = useAuth();
  const theme = useTheme();
  const colors = theme.palette;
  const navigate = useNavigate();

  const [filters, setFilters] = useState<InputValues[] | null>(null);
  const [page, setPage] = useState<number>(0);
  const observer = useRef<IntersectionObserver | null>(null);

  const { intellifloTenantData, hasMore, isFetching } = useIntellifloTenant(page, filters);

  const lastItem = useCallback((node: HTMLTableRowElement) => {
    if (isFetching) return;

    if (observer.current) {
      observer.current?.disconnect();
    }

    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(page => {
          return page + 1;
        });
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  }, [isFetching, hasMore]);


  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Header title="Intelliflo Integration" subtitle="Manage intelliflo integration" />
        {hasPermission(attributes, "create:io-integrations" as Permission) && (
          <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={() => navigate(generatePath(Paths.IntegrationNew))}
            >
                Add Intelliflo Tenant
            </Button>
          </Box>
        )}
      </Box>
      <Filter setFilters={(filters) => {
        setPage(0);
        setFilters(filters);
      }} />
      <Box
        flexGrow={1}
        m="30px 0 0 0"
        sx={{
          display: "flex",
          flexDirection: "column",
          "& .MuiDataGrid-root": {
            border: `1px solid ${colors.cardBorderColor}`,
            borderRadius: "2px",
          },
          "& .name-column--cell": {
            color: colors.grey[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-toolbarContainer": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]}`,
          },
          "& .MuiDataGrid-paper": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
        }}
      >
        <TableContainer sx={{ flexGrow: 1, maxHeight: "65vh" }} component={Paper}>
          <Table stickyHeader aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Tooltip
                    title="A tenant is a unique instance of intelliflo Office, identified by a tenantId, representing either a single firm or a network of firms."
                    placement="top"
                  >
                    <Typography noWrap>
                      Intelliflo Tenant ID
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip
                    title="Identifier used to identify a tenant in Hubwise portal"
                    placement="top"
                  >
                    <Typography noWrap>
                      Hubwise Customer Identifier
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip
                    title="Name of tenant in Hubwise portal"
                    placement="top"
                  >
                    <Typography noWrap>
                      Hubwise Customer Name
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Typography noWrap>
                    Action
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {intellifloTenantData?.map((row, index) => {
                if (intellifloTenantData?.length === index + 1) {
                  return <TenantRow row={row!} key={index} attributes={attributes} ref={lastItem} />;
                } else {
                  return <TenantRow row={row!} key={index} attributes={attributes} />;
                }
              })}

              {!isFetching && !hasMore && (
                <TablePlaceholder colSpan={9}>
                  <Typography variant="h5">No More Data</Typography>
                </TablePlaceholder>
              )}

              {isFetching && (
                <TablePlaceholder colSpan={9}>
                  <CircularProgress color="primary" />
                </TablePlaceholder>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default TenantTable;
