import _omit from "lodash/omit";
import { get, put, post } from "aws-amplify/api";
import type { BaseQueryFn } from "@reduxjs/toolkit/query/react";
import { createApi, FetchBaseQueryMeta } from "@reduxjs/toolkit/query/react";

type Params = {
  page?: number;
  size?: number;
  limit?: number;
  skip?: number;
} & Record<string, string | number>;

const transformParams = (params: Params): Record<string, string> => {
  const stringifiedParams = Object.fromEntries(
    Object.entries(params)
      .map(([key, value]) => [key, String(value)])
  );
  if (stringifiedParams.page && !("limit" in stringifiedParams) && !("skip" in stringifiedParams)) {
    const { page, size = "50", ...result } = stringifiedParams;
    result.limit = size;
    result.skip = String((+page - 1) * +size);
    return result;
  }
  return stringifiedParams;
};

export enum ApiGateways {
  HubwiseHttpApi = "HubwiseHttpApi",
  PortalApi = "PortalApi",
  IntellifloApi = "IntellifloApi",
}

export enum Methods {
  query = "get",
  mutation = "post",
  put = "put",
}

type ExtraOptions = {
  method?: Methods;
  gateway?: ApiGateways;
};

type Args = {
  url: string;
  queryParams?: Record<string, string>;
  body?: Record<string, any>;
};

type AmlifyBaseQuery = BaseQueryFn<
  string | Args,
  unknown,
  unknown,
  ExtraOptions,
  ExtraOptions & FetchBaseQueryMeta
>;

const API = {
  get,
  put,
  post,
};

const amplifyBaseQuery: AmlifyBaseQuery = async (args, api, extraOptions) => {
  try {
    const method = extraOptions?.method || Methods[api.type];
    const apiName = extraOptions?.gateway || ApiGateways.PortalApi;
    const path =
      typeof args === "string"
        ? args
        : args.url + parseParams(args.queryParams);
    const options = typeof args === "string" ? {} : _omit(args, "url");

    if (options.queryParams) {
      options.queryParams = transformParams(options.queryParams);
    }

    const result = await API[method]({ apiName, path, options }).response;

    // if (result.error) {
    //   throw result;
    // }

    return {
      data: result,
      // meta: result.meta && { ...result.meta, ...extraOptions },
    };
  } catch (err: any) {
    console.log("Error in baseApi: ", err);
    return {
      error: {
        status: err.response?.status,
        message: err.response?.data?.message,
        data: err.response?.data || err.message,
      },
    };
  }
};

export const restBaseApi = createApi({
  baseQuery: amplifyBaseQuery,
  endpoints: () => ({}),
  reducerPath: "restBaseApi"
});

export const parseParams = (obj?: Record<string, string | number>): string => {
  if (!obj) return "";
  return (
    "?" +
    new URLSearchParams(
      transformParams(obj) as Record<string, string>
    ).toString()
  );
};
