import React, { useEffect, useState } from "react";
import { Box, Card, CircularProgress, MenuItem, Select, SelectChangeEvent } from "@mui/material";

import { ActiveGroup } from "./types";

import { useGetIpGroupsQuery } from "./api";
import IpSetTable from "./IpSetTable";
import Header from "../../../components/Header";
import PageWrapper from "components/PageWrapper";


const IpSet = () => {
  const [activeGroup, setActiveGroup] = useState<ActiveGroup | null>(null);
  const { data, isLoading } = useGetIpGroupsQuery();
  const groupsList = data?.getIpSetGroups?.items;

  const handeSetActiveGroup = (event: SelectChangeEvent) => {
    const newActiveGroup = groupsList?.find((el) => el.ipSet.name === event.target.value);
    if (!newActiveGroup) return;
    setActiveGroup({ ...newActiveGroup.ipSet, dbKey: newActiveGroup.dbKey });
  };

  useEffect(() => {
    const firstGroup = data?.getIpSetGroups.items?.find((group) => group.isActive);
    if (firstGroup) {
      setActiveGroup({ ...firstGroup.ipSet, dbKey: firstGroup.dbKey });
    }
  }, [data]);

  return (
    <PageWrapper>
      <Box overflow="auto" display="flex" flexDirection="column" flexGrow={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="IP whitelists" subtitle="Manage and view IP addresses whitelist" />
          {groupsList && (
            <Select size="small" value={activeGroup?.name || ""} onChange={handeSetActiveGroup}>
              {groupsList.map((el) => (
                <MenuItem key={el.ipSet.name} value={el.ipSet.name}>{el.name}</MenuItem>
              ))}
            </Select>
          )}
        </Box>

        {isLoading && (
          <Box sx= {{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress color="primary"/>
          </Box>
        )}

        {activeGroup && (
          <IpSetTable
            ipSetGroup={activeGroup}
          />
        )}
      </Box>
    </PageWrapper>
  );
};

export default IpSet;
