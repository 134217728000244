import React, { forwardRef, useState } from "react";

import {
  TableCell,
  TableRow,
  Snackbar,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  colors
} from "@mui/material";


import { useNavigate } from "react-router";
import MenuComponent, { MenuItem } from "components/MenuComponent";
import { Adviser } from "../../model";
import adviserStore from "../../api";
import { enqueueSnackbar } from "notistack";
import { hasPermission } from "core/utils/roles";

interface TableRowProps {
    row: Adviser;
    attributes: { [key: string]: any };
}

const AdviserUsersRow = forwardRef<HTMLTableRowElement, TableRowProps>((props) => {
  const navigate = useNavigate();
  const row = props.row;
  const attributes = props.attributes;
  const { disableMFAAdviser } = adviserStore;

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleDisableMFA = async () => {
    try {
      await disableMFAAdviser({ username: row.username, status: false });
      enqueueSnackbar(`MFA disabled successfully for ${row.username}`, { variant: "success" });
      handleCloseDialog(); // Close the dialog after success

    } catch (error) {
      enqueueSnackbar(`Error disabling MFA for ${row.username}`, { variant: "error" });
      handleCloseDialog(); // Close the dialog even if there's an error


    }
  };

  const options: MenuItem[] = [
    {
      label: hasPermission(attributes, "update:advisor-onboarding") ? "Edit" : "View",
      onClick: () => navigate("/adviser-users/add-edit/" + row.id)
    },
    ...(hasPermission(attributes, "update:advisor-onboarding") ? [{
      label: "Disable MFA",
      onClick: handleOpenDialog,
    }] : []),
    {
      label: "User Activity",
      onClick: () => navigate(`/adviser-users/user-activity/${row.id}`, { state: { username: row.username } })
    },
  ];

  return (
    <React.Fragment>
      <TableRow
        sx={{ borderBottom: "none", "& > *": { borderBottom: "unset" } }}
      >
        <TableCell>{row?.first_name} {row?.last_name}</TableCell>
        <TableCell>{row?.username}</TableCell>
        <TableCell>{row?.email}</TableCell>
        <TableCell>{row?.created_at}</TableCell>
        <TableCell>
          <MenuComponent options={options} />
        </TableCell>
      </TableRow>
      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="confirm-disable-mfa"
      >
        <DialogTitle id="confirm-disable-mfa">
          <Typography variant="h4">Confirm Disable MFA</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to disable MFA for {row.username}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDisableMFA}
            sx={{
              backgroundColor: colors.red[600], // Use red accent color
              color: "white",
              "&:hover": {
                backgroundColor: colors.red[800], // Darker red for hover state
              },
            }}
          >
            Disable MFA
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});

export default AdviserUsersRow;
