/* eslint-disable */ /**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run graphql-codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../../app/services/types.generated';

import { api } from 'app/graphqlBaseApi';
module.hot?.accept();
export type GetJobsMetaDataQueryVariables = Types.Exact<{
  jobName: Types.Scalars['String']['input'];
}>;


export type GetJobsMetaDataQuery = { __typename?: 'Query', getJobsMetaData: { __typename?: 'GetJobsMetaDataResponse', statusCode: number, success: boolean, data?: { __typename?: 'JobsMetaData', name?: string, title?: string, stats?: Array<{ __typename?: 'JobStats', name: string, title: string, type: string, value: number }> } } };

export type GetJobsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetJobsQuery = { __typename?: 'Query', getJobs: { __typename?: 'GetJobsResponse', statusCode: number, success: boolean, data?: Array<{ __typename?: 'JobType', name: string, title: string }> } };


export const GetJobsMetaDataDocument = `
    query GetJobsMetaData($jobName: String!) {
  getJobsMetaData(jobName: $jobName) {
    statusCode
    success
    data {
      name
      title
      stats {
        name
        title
        type
        value
      }
    }
  }
}
    `;
export const GetJobsDocument = `
    query GetJobs {
  getJobs {
    data {
      name
      title
    }
    statusCode
    success
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    GetJobsMetaData: build.query<GetJobsMetaDataQuery, GetJobsMetaDataQueryVariables>({
      query: (variables) => ({ document: GetJobsMetaDataDocument, variables })
    }),
    GetJobs: build.query<GetJobsQuery, GetJobsQueryVariables | void>({
      query: (variables) => ({ document: GetJobsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetJobsMetaDataQuery, useLazyGetJobsMetaDataQuery, useGetJobsQuery, useLazyGetJobsQuery } = injectedRtkApi;

