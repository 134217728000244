import React, { forwardRef } from "react";

import {
  TableCell,
  TableRow
} from "@mui/material";

import { useNavigate } from "react-router";
import MenuComponent, { MenuItem } from "components/MenuComponent";
import { IoConfigResponse } from "app/services/types.generated";
import { hasPermission } from "core/utils/roles";

interface TableRowProps {
    row: IoConfigResponse;
    attributes: { [key: string]: any }
  }

const IOConfigRow = forwardRef<HTMLTableRowElement, TableRowProps>((props) => {
  const navigate = useNavigate();
  const row = props.row;
  const attributes = props.attributes;

  const options: MenuItem[] = [
    {
      label: hasPermission(attributes, "update:io-app-config") ? "Edit" : "View",
      onClick: () => navigate("/io-config/add-edit/" + row.id)
    }
  ];

  return (
    <React.Fragment>
      <TableRow
        sx={{ borderBottom: "none", "& > *": { borderBottom: "unset" } }}
      >
        <TableCell>{row?.appId}</TableCell>
        <TableCell>{row?.type}</TableCell>
        <TableCell>{row?.providerId}</TableCell>
        <TableCell>{row?.provider}</TableCell>
        <TableCell>
          <MenuComponent options={options} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
});

export default IOConfigRow;
