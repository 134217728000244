import React, { useEffect } from "react";
import { IntegrationService } from "../api";
import { Box, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography, Accordion, AccordionSummary, AccordionDetails, Button } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CardComponent } from "components/card/Card";
import { useNavigate } from "react-router";
import { CreateIntellifloTenantMutation, CreateIntellifloTenantMutationVariables, UpdateIntellifloTenantMutation } from "../api/intelliflo-integration.generated";
import { enqueueSnackbar } from "notistack";
import { FieldArray, Formik, FormikErrors, useFormikContext } from "formik";
import { useGetAllIoConfigQuery } from "core/scenes/io-config/api/io-config.generated";
import { CreateIntellifloTenantInput, IntellifloIntegrationLegalType, LegalEntitiesType } from "app/services/types.generated";
import { useAuth } from "app/authHooks";
import { hasPermission, Permission } from "core/utils/roles";

interface EditIntegrationProps {
  formValue: IntegrationService.CombinedSchemaType,
  isEdit: boolean,
  id?: string
}

function EditIntegration({ formValue, isEdit, id }: EditIntegrationProps) {
  const { attributes } = useAuth();
  const navigate = useNavigate();

  const canUpdateIOtenant = hasPermission(attributes, "update:io-integrations" as Permission);

  const [createIntellifloTenant] = IntegrationService.useCreateIntellifloTenantMutation();
  const [updateIntellifloTenant] = IntegrationService.useUpdateIntellifloTenantMutation();

  const { data: ioConfigs } = useGetAllIoConfigQuery({ limit: 1000 });
  const ioConfigList = ioConfigs?.getAllIoConfig?.data?.data || [];

  const handleSubmitForm = async (values: IntegrationService.CombinedSchemaType) => {
    if (values?.intellifloIntegrationLegalEntitiesInput?.length < 1) {
      enqueueSnackbar("Please insert at least one legal entities.", {
        variant: "error", anchorOrigin: {
          vertical: "top",
          horizontal: "right"
        }
      });
      return;
    }

    if (isEdit) {
      const updateResponse = await updateIntellifloTenant({
        input: {
          integrationAndLegalEntitiesInput: values.intellifloIntegrationLegalEntitiesInput,
          intellifloTenantInput: values.intellifloTenantInput,
        }
      }) as { data: UpdateIntellifloTenantMutation };
      console.log("update is working");

      if (updateResponse.data.updateIntellifloTenant.success) {
        enqueueSnackbar("Intelliflo Tenant Updated Successfully.", { variant: "success" });
      } else {
        enqueueSnackbar("Error Updating Intelliflo Tenant.", { variant: "error" });
      }
      return;
    }

    const createIntellifloTenantData: CreateIntellifloTenantMutationVariables = {
      input: {
        intellifloTenantInput: values.intellifloTenantInput,
        integrationAndLegalEntitiesInput: values.intellifloIntegrationLegalEntitiesInput,
      },
    };

    const createResponse = await createIntellifloTenant(createIntellifloTenantData) as { data: CreateIntellifloTenantMutation };

    if (createResponse?.data?.createIntellifloTenant?.success) {
      enqueueSnackbar("Intelliflo Tenant Added Successfully", { variant: "success" });
      navigate("/integrations");
    } else {
      enqueueSnackbar(createResponse?.data?.createIntellifloTenant?.errorMessage || "Error Creating Intelliflo Tenant", { variant: "error" });
    }
    return;
  };

  return (
    <>
      <Formik
        onSubmit={handleSubmitForm}
        initialValues={formValue}
        validationSchema={IntegrationService.CombinedSchema}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          touched,
          handleBlur
        }) => {
          return <form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <CardComponent
                title={isEdit
                  ? canUpdateIOtenant
                    ? "Edit Integration"
                    : "View Integration"
                  : "Add Integration"}
                cardContent={
                  <Box sx={{ "& > *:not(:last-child)": { mb: 8 } }}>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="integration-type">Integration Type</InputLabel>
                          <Select
                            name="intellifloTenantInput.type"
                            labelId="integration-type"
                            value={values.intellifloTenantInput?.type}
                            error={!!touched.intellifloTenantInput?.type &&
                              !!errors?.intellifloTenantInput?.type}
                            disabled={isEdit}
                            id="integration-type"
                            label="Integration Type"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            {Object.values(IntegrationService.IntegrationType).map((value, index) => (
                              <MenuItem key={index} value={value}>
                                {value.toUpperCase()}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                        <FormControl variant="standard" style={{ width: "100%" }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Intelliflo Tenant ID"
                            name="intellifloTenantInput.externalId"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.intellifloTenantInput?.externalId}
                            error={!!touched.intellifloTenantInput?.externalId && !!errors?.intellifloTenantInput?.externalId}
                            helperText={!!touched.intellifloTenantInput?.externalId && errors?.intellifloTenantInput?.externalId}
                            disabled={isEdit}
                            sx={{
                              gridColumn: "span 4",
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                        <FormControl variant="standard" style={{ width: "100%" }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Hubwise Customer Identifier"
                            name="intellifloTenantInput.internalId"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.intellifloTenantInput?.internalId}
                            error={!!touched?.intellifloTenantInput?.internalId && !!errors?.intellifloTenantInput?.internalId}
                            helperText={!!touched.intellifloTenantInput?.internalId && errors?.intellifloTenantInput?.internalId}
                            disabled={isEdit}
                            sx={{
                              gridColumn: "span 4",
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                        <FormControl variant="standard" style={{ width: "100%" }}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Hubwise Customer Name"
                            name="intellifloTenantInput.metadata.displayName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.intellifloTenantInput?.metadata?.displayName}
                            error={!!touched.intellifloTenantInput?.metadata?.displayName && !!errors?.intellifloTenantInput?.metadata?.displayName}
                            helperText={!!touched.intellifloTenantInput?.metadata?.displayName && errors?.intellifloTenantInput?.metadata?.displayName}
                            disabled={isEdit}
                            sx={{
                              gridColumn: "span 4",
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="provider-app-id">Provider App Id</InputLabel>
                          <Select
                            fullWidth
                            id="provider-app-id"
                            labelId="provider-app-id"
                            label="Provider App Id"
                            placeholder="Provider App Id"
                            name="intellifloTenantInput.providerAppId"
                            value={values.intellifloTenantInput?.providerAppId}
                            error={!!touched.intellifloTenantInput?.providerAppId && !!errors.intellifloTenantInput?.providerAppId}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            disabled={!canUpdateIOtenant}
                          >
                            {ioConfigList?.map((value, index) => (
                              <MenuItem key={index} value={value.appId}>
                                {value.appId}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={6} xs={10} style={{ textAlign: "left" }}>
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel id="bulk-val-app-id">Bulk Valuation App Id</InputLabel>
                          <Select
                            fullWidth
                            labelId="bulk-val-app-id"
                            id="bulk-val-app-id"
                            label="Bulk Valuation App Id"
                            placeholder="Bulk Valuation App Id"
                            name="intellifloTenantInput.bulkValAppId"
                            value={values.intellifloTenantInput?.bulkValAppId}
                            error={!!touched.intellifloTenantInput?.bulkValAppId && !!errors.intellifloTenantInput?.bulkValAppId}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            disabled={!canUpdateIOtenant}
                          >
                            {ioConfigList?.map((value, index) => (
                              <MenuItem key={index} value={value.appId}>
                                {value.appId}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                }
              />


              <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                Legal Entities
              </Typography>
              <Box m={2}>
                <div>
                  <FieldArray
                    name="intellifloIntegrationLegalEntitiesInput"
                    render={arrayHelpers => (
                      <>
                        <Grid container spacing={2}  >
                          {values?.intellifloIntegrationLegalEntitiesInput.map((item, index) => (
                            <Accordion key={index} sx={{ width: "100%", mb: 2 }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}

                              >
                                <Typography variant="h5" >
                                  {item.legalEntity.customerId ? item.legalEntity.customerId : "Legal Entity"}
                                </Typography>
                              </AccordionSummary>

                              <AccordionDetails>

                                <Grid container spacing={2} key={index} sx={{ mt: -4 }}>

                                  <Grid item container spacing={2} md={12} xs={12} mt="5px">
                                    <Grid item md={3} xs={12}>
                                      <FormControl variant="standard" style={{ width: "100%" }}>
                                        <TextField
                                          fullWidth
                                          variant="outlined"
                                          type="text"
                                          label="Customer Id"
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.intellifloIntegrationLegalEntitiesInput?.[index]?.legalEntity?.customerId}
                                          name={`intellifloIntegrationLegalEntitiesInput[${index}].legalEntity.customerId`}
                                          error={
                                            !!touched?.intellifloIntegrationLegalEntitiesInput?.[index]?.legalEntity?.customerId &&
                                            !!(errors?.intellifloIntegrationLegalEntitiesInput?.[index] as FormikErrors<IntellifloIntegrationLegalType>)?.legalEntity?.customerId
                                          }
                                          helperText={!!touched?.intellifloIntegrationLegalEntitiesInput?.[index]?.legalEntity?.customerId &&
                                            (errors?.intellifloIntegrationLegalEntitiesInput?.[index] as FormikErrors<IntellifloIntegrationLegalType>)?.legalEntity?.customerId
                                          }
                                          InputLabelProps={{ shrink: true }}
                                          disabled={!canUpdateIOtenant}

                                        />
                                      </FormControl>
                                    </Grid>

                                    <Grid item md={3} xs={12}>
                                      <FormControl variant="standard" style={{ width: "100%" }}>
                                        <TextField
                                          fullWidth
                                          variant="outlined"
                                          type="text"
                                          label="External Group Id"
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.intellifloIntegrationLegalEntitiesInput?.[index]?.legalEntity?.groupId}
                                          name={`intellifloIntegrationLegalEntitiesInput[${index}].legalEntity.groupId`}
                                          error={
                                            !!touched?.intellifloIntegrationLegalEntitiesInput?.[index]?.legalEntity?.groupId &&
                                            !!(errors?.intellifloIntegrationLegalEntitiesInput?.[index] as FormikErrors<IntellifloIntegrationLegalType>)?.legalEntity?.groupId
                                          }
                                          helperText={!!touched?.intellifloIntegrationLegalEntitiesInput?.[index]?.legalEntity?.groupId &&
                                            (errors?.intellifloIntegrationLegalEntitiesInput?.[index] as FormikErrors<IntellifloIntegrationLegalType>)?.legalEntity?.groupId
                                          }
                                          InputLabelProps={{ shrink: true }}
                                          disabled={!canUpdateIOtenant}

                                        />
                                      </FormControl>
                                    </Grid>

                                    <Grid item md={3} xs={12}>
                                      <FormControl variant="standard" style={{ width: "100%" }}>
                                        <TextField
                                          fullWidth
                                          variant="outlined"
                                          type="text"
                                          label="FCA Reference"
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.intellifloIntegrationLegalEntitiesInput?.[index]?.legalEntity?.fcaRefNo}
                                          name={`intellifloIntegrationLegalEntitiesInput[${index}].legalEntity.fcaRefNo`}
                                          error={
                                            !!touched?.intellifloIntegrationLegalEntitiesInput?.[index]?.legalEntity?.fcaRefNo &&
                                            !!(errors?.intellifloIntegrationLegalEntitiesInput?.[index] as FormikErrors<IntellifloIntegrationLegalType>)?.legalEntity?.fcaRefNo
                                          }
                                          helperText={!!touched?.intellifloIntegrationLegalEntitiesInput?.[index]?.legalEntity?.fcaRefNo &&
                                            (errors?.intellifloIntegrationLegalEntitiesInput?.[index] as FormikErrors<IntellifloIntegrationLegalType>)?.legalEntity?.fcaRefNo
                                          }
                                          InputLabelProps={{ shrink: true }}
                                          disabled={!canUpdateIOtenant}
                                        />

                                      </FormControl>
                                    </Grid>
                                  </Grid>

                                  <Grid item md={6} xs={10} style={{ textAlign: "left", alignContent: "center" }}>
                                    <FormControl variant="standard" style={{ width: "100%" }}>
                                      <FormControlLabel
                                        name={`intellifloIntegrationLegalEntitiesInput[${index}].integrationSettings.settings.wealthLinkModelSync.enabled`}
                                        control={<Checkbox
                                          checked={
                                            values.intellifloIntegrationLegalEntitiesInput?.[index]?.integrationSettings?.settings?.wealthLinkModelSync?.enabled ||
                                            false
                                          }
                                          onChange={handleChange} />}
                                        label="Model Sync Enabled"
                                        labelPlacement="end"
                                        disabled={!canUpdateIOtenant}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item md={6} xs={10} style={{ textAlign: "left", alignContent: "center" }}>
                                    <FormControl variant="standard" style={{ width: "100%" }}>
                                      <FormControlLabel
                                        name={`intellifloIntegrationLegalEntitiesInput[${index}].integrationSettings.settings.wealthLinkIncomeStatement.enabled`}
                                        control={<Checkbox
                                          checked={
                                            values.intellifloIntegrationLegalEntitiesInput?.[index]?.integrationSettings?.settings?.wealthLinkIncomeStatement?.enabled || false
                                          } onChange={handleChange} />}
                                        label="Income Statement"
                                        labelPlacement="end"
                                        disabled={!canUpdateIOtenant}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item md={6} xs={10} style={{ textAlign: "left", alignContent: "center" }}>
                                    <FormControl variant="standard" style={{ width: "100%" }}>
                                      <FormControlLabel
                                        name={`intellifloIntegrationLegalEntitiesInput[${index}].integrationSettings.settings.allowClientImport`}
                                        control={<Checkbox
                                          checked={
                                            values.intellifloIntegrationLegalEntitiesInput?.[index]?.integrationSettings?.settings?.allowClientImport || false
                                          } onChange={handleChange} />}
                                        label="Allow Client Import"
                                        labelPlacement="end"
                                        disabled={values.intellifloTenantInput?.type === IntegrationService.IntegrationType.GATEWAY || !canUpdateIOtenant}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item md={6} xs={10} style={{ textAlign: "left", alignContent: "center" }}>
                                    <FormControl variant="standard" style={{ width: "100%" }}>
                                      <FormControlLabel
                                        name={`intellifloIntegrationLegalEntitiesInput[${index}].integrationSettings.settings.allowClientRelationshipImport`}
                                        control={<Checkbox
                                          checked={
                                            values.intellifloIntegrationLegalEntitiesInput?.[index]?.integrationSettings?.settings?.allowClientRelationshipImport || false
                                          }
                                          onChange={handleChange} />}
                                        label="Allow Client Relationship Import"
                                        labelPlacement="end"
                                        disabled={values.intellifloTenantInput?.type === IntegrationService.IntegrationType.GATEWAY || !canUpdateIOtenant}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item md={6} xs={10} style={{ textAlign: "left", alignContent: "center" }}>
                                    <FormControl variant="standard" style={{ width: "100%" }}>
                                      <FormControlLabel
                                        name={`intellifloIntegrationLegalEntitiesInput[${index}].integrationSettings.settings.allowDocumentUpload`}
                                        control={<Checkbox
                                          checked={
                                            values.intellifloIntegrationLegalEntitiesInput?.[index]?.integrationSettings?.settings?.allowDocumentUpload || false
                                          }
                                          onChange={handleChange} />}
                                        label="Allow Document Upload"
                                        labelPlacement="end"
                                        disabled={values.intellifloTenantInput?.type === IntegrationService.IntegrationType.GATEWAY}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item md={6} xs={10} style={{ textAlign: "left", alignContent: "center" }}>
                                    <FormControl variant="standard" style={{ width: "100%" }}>
                                      <FormControlLabel
                                        name={`intellifloIntegrationLegalEntitiesInput[${index}].integrationSettings.settings.allowWealthLinkBulkValuation`}
                                        control={<Checkbox
                                          checked={
                                            values.intellifloIntegrationLegalEntitiesInput?.[index]?.integrationSettings?.settings?.allowWealthLinkBulkValuation || false
                                          }

                                          onChange={handleChange} />}
                                        label="Allow WealthLink Bulk Valuation"
                                        labelPlacement="end"
                                        disabled={!canUpdateIOtenant}
                                      />
                                    </FormControl>
                                  </Grid>

                                  {canUpdateIOtenant && values?.intellifloIntegrationLegalEntitiesInput.length > 1 && (
                                    <Grid item md={12} xs={12}>
                                      <Box width="100%" display="flex" justifyContent="flex-end">

                                        <Button
                                          type="button"
                                          variant="outlined"
                                          sx={{ maxHeight: "38px" }}
                                          color="primary"
                                          onClick={() => {
                                            console.log("arrayHelpers:", arrayHelpers);
                                            arrayHelpers.remove(index);
                                          }}
                                        >
                                            Remove
                                        </Button>
                                      </Box>
                                    </Grid>
                                  )}
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                        </Grid>
                        {canUpdateIOtenant && (
                          <Box width="100%" display="flex" mt="5px" justifyContent="flex-end">
                            <Button
                              color="primary"
                              style={{ marginTop: 10, width: "150px" }}
                              variant="contained"
                              type="button"
                              onClick={() =>
                                arrayHelpers.push({
                                  integrationSettings: {
                                    id: "",
                                    settings: {
                                      allowClientImport: false,
                                      allowClientRelationshipImport: false,
                                      allowDocumentUpload: false,
                                      allowWealthLinkBulkValuation: false,
                                      type: "",
                                      wealthLinkIncomeStatement: { enabled: false },
                                      wealthLinkModelSync: { enabled: false },
                                    },
                                  },
                                  legalEntity: {
                                    customerId: "",
                                    groupId: "",
                                    fcaRefNo: "",
                                  },
                                })
                              }
                            >
                                Add More
                            </Button>
                          </Box>
                        )}

                      </>
                    )}
                  />
                </div>
              </Box>
              <Watcher />
            </Box>
            {canUpdateIOtenant && (
              <Box width="100%">
                <Button
                  color="primary"
                  variant="contained"
                >
                    Save
                </Button>
              </Box>
            )}
          </form>;
        }}
      </Formik>
    </>
  );
}


export default EditIntegration;


const Watcher: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<CreateIntellifloTenantInput>();

  useEffect(() => {
    const integrationType = values.intellifloTenantInput?.type;

    if (integrationType === IntegrationService.IntegrationType.GATEWAY) {
      setFieldValue("intellifloIntegrationInput.settings.allowClientImport", false);
      setFieldValue("intellifloIntegrationInput.settings.allowClientRelationshipImport", false);
      setFieldValue("intellifloIntegrationInput.settings.allowDocumentUpload", false);
    }
  }, [values.intellifloTenantInput?.type, setFieldValue]);

  return null;
};

