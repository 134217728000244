import { Box } from "@mui/material";
import InvestorUsersTable from "./investor-users-table";
import PageWrapper from "components/PageWrapper";

function InvestorUsers() {
  return (
    <PageWrapper>
      <InvestorUsersTable />
    </PageWrapper>
  );
}

export default InvestorUsers;
