import React from "react";
import { FieldProps } from "formik";
import _omit from "lodash/omit";
import { Checkbox, CheckboxProps } from "@mui/material";

type Props = CheckboxProps & FieldProps;
const CheckboxInput: React.FC<Props> = ({
  field,
  ...CheckboxProps
}) => {
  return (
    <Checkbox
      {...(_omit(CheckboxProps, "form", "meta"))}
      id={field.name}
      name={field.name}
      checked={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
    />
  );
};

export default CheckboxInput;
