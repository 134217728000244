import {
  Accordion, AccordionDetails, AccordionSummary, Autocomplete, AutocompleteChangeReason, Box, Button, CircularProgress, Link, Pagination, Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useTheme
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import Header from "components/Header";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { observer } from "mobx-react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useGetUserEventsQuery } from "../api/user-activity.generated";
import UsersActivityRow from "./user-activity-row";
import { DATE_DIRECTIONS, EVENT_TYPES, USER_TYPES, UserEvent } from "../model/index";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const TablePlaceholder: React.FC<{ children: ReactNode }> = ({ children }) => (
  <TableRow>
    <TableCell colSpan={9} align="center">
      {children}
    </TableCell>
  </TableRow>
);

function UsersActivityTable() {
  const theme = useTheme();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize] = useState(10);
  const [sortField, setSortField] = useState<string>("eventTimestamp");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [isExpanded, setIsExpanded] = useState(false);
  const [userType, setUserType] = useState("adviser");
  const [eventType, setEventType] = useState<string | null>(null);
  const [selectedDateString, setSelectedDateString] = useState<string | any>(null);
  const [selectedDatePicker, setSelectedDatePicker] = useState<Date | null>(null);
  const [dateDirection, setDateDirection] = useState<string | undefined>(
    DATE_DIRECTIONS.find((option) => option === "After") || undefined
  );
  const [userEvents, setUserEvents] = useState<UserEvent[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();
  const userIdNum = userId!=undefined ? parseInt(userId) : -1;
  console.log(`UserIdString: ${userId}, userIdNum: ${userIdNum}`);
  console.log("Location: ", location);
  const username = location.state && location.state.username ? location.state.username : "";
  console.log(username);
  useEffect(() => {
    if (location.pathname.includes("adviser")) {
      setUserType("adviser");
    } else if (location.pathname.includes("investor")) {
      setUserType("investor");
    }
  }, [location.pathname]);

  const { data, isLoading, error } = useGetUserEventsQuery(
    {
      getUserEventsFilter: {
        dateFilter: { dateDirections: dateDirection, dateFilterValue: selectedDateString || "" },
        eventType: eventType || undefined,
        pageIndex: currentPage - 1,
        pageSize: pageSize,
        sortBy: sortField,
        sortOrder: sortOrder,
        userType: userType,
        userId: userIdNum,
      }
    }
  );

  useEffect(() => {
    if (data) {
      const events: UserEvent[] = data?.getUserEvents.data?.map((event) => ({
        ...event,
        userType: userType,
      })) || [];

      setUserEvents(events);
      setTotalPages(Math.ceil((data?.getUserEvents.total || 0) / pageSize));
    }
  }, [data]);

  useEffect(() => {
    onInit(userType);
  }, [userType, eventType, currentPage, sortField, sortOrder, dateDirection, selectedDateString]);

  const onInit = async (userType: string) => {
    try {
      const events: UserEvent[] =
        data?.getUserEvents.data?.map((event) => ({
          ...event,
          userType: userType,
        })) || [];
      setUserEvents(events || []);
      setTotalPages(Math.ceil((data?.getUserEvents.total || 0) / pageSize));
      console.log(events);
    } catch (error) {
      console.error("Failed to fetch user events:", error);
    }
  };

  const handleSort = (field: string) => {
    const isAsc = sortField === field && sortOrder === "asc";
    setSortField(field);
    setSortOrder(isAsc ? "desc" : "asc");
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between">
        <Box sx={{ cursor: "pointer", fontSize: "14px", lineHeight: "20px", marginBottom: "16px" }}>
          <Link underline="hover" onClick={() => navigate(`/${userType}-users/`)}>
            <ArrowBackIcon sx={{ width: "16px", height: "16px", marginRight: "4px" }} /> Back to {userType.charAt(0).toUpperCase() + userType.slice(1)} User
          </Link>
        </Box>
        <Header title="User Activity" subtitle={"Username: " + username} />
        <Box display="flex">
          <Autocomplete
            disablePortal
            options={DATE_DIRECTIONS}
            sx={{ width: 1, marginRight: 2 }}
            value={dateDirection}
            onChange={(
              event: React.SyntheticEvent,
              value: string | null,
              reason: AutocompleteChangeReason
            ) => {
              console.log("Selected Direction: ", value);
              setDateDirection(value || undefined);
            }}
            renderInput={(params) => <TextField {...params} label="Date Direction" />}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Event Date"
              value={selectedDatePicker ? selectedDatePicker : null}
              sx={{ width: 1 }}
              onChange={(newDate: Date | null) => {
                if (newDate) {
                  const formattedDate = new Date(newDate).toISOString();
                  setSelectedDateString(formattedDate);
                  setSelectedDatePicker(newDate);
                  console.log("Formatted Date:", formattedDate);
                } else {
                  setSelectedDateString(null);
                  setSelectedDatePicker(null);
                }
              }}
              slotProps={{
                textField: {
                  InputProps: { readOnly: false, disabled: false },
                },
              }}
            />
          </LocalizationProvider>
        </Box>
      </Box>

      <Box py="10px">
        <Accordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
          <AccordionSummary sx={{ "&.Mui-expanded": { minHeight: "32px" } }} expandIcon={<ExpandMoreIcon />}>
            <Typography>Filters</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "16px" }}>
            <Box display="flex" flexDirection="row" gap="20px" alignItems="flex-end">
              <Autocomplete
                disablePortal
                disabled={true}
                options={USER_TYPES}
                sx={{ flex: 1, maxWidth: 0.5 }}
                getOptionLabel={(option: { label: string, value: string }) => option.label}
                value={USER_TYPES.find(option => option.value === userType) || null}
                onChange={(event, value) => setUserType(value?.value || "")}
                renderInput={(params) => <TextField {...params} label="User Type" />}
              />

              <Autocomplete
                disablePortal
                options={EVENT_TYPES}
                sx={{ flex: 1, maxWidth: 0.5 }}
                value={EVENT_TYPES.find(option => option.value === eventType) || null}
                getOptionLabel={(option: { label: string, value: string }) => option.label}
                onChange={(event, value) => setEventType(value?.value || "")}
                renderInput={(params) => <TextField {...params} label="Event Type" />}
              />

              <Button
                variant="contained"
                color="error"
                sx={{ flex: 1, maxWidth: 0.1, paddingTop: 2, paddingBottom: 2 }}
                onClick={() => {
                  setEventType(null);
                  setSelectedDateString(null);
                  setSelectedDatePicker(null);
                }}
              >
                <ClearIcon />
                <Typography fontWeight="bold" color="white ">Reset  </Typography>
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>

      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {[
                { field: "eventType", label: "Event Type" },
                { field: "eventTimestamp", label: "Event Timestamp" },
                { field: "ipAddress", label: "IP Address" },
                { field: "deviceInfo", label: "Device Info At" },
                { field: "lastSuccessfulLoginAt", label: "Last Successful Login" },
                { field: "lastFailedLoginAt", label: "Last Failed Login" }
              ].map(({ field, label }) => (
                <TableCell key={field} onClick={() => handleSort(field)}>
                  <Typography noWrap variant="h5">
                    {label}{" "}
                    <span style={{ opacity: 0.75 }}>
                      {sortField === field ? (sortOrder === "asc" ? "↑" : "↓") : ""}
                    </span>
                  </Typography>
                </TableCell>
              ))}
              <TableCell>
                <Typography noWrap variant="h5">
                  See Details
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TablePlaceholder>
                <CircularProgress />
              </TablePlaceholder>
            )}
            {(!isLoading && !userEvents?.length) && (
              <TablePlaceholder>
                <Typography variant="h5">No Data</Typography>
              </TablePlaceholder>
            )}
            {!isLoading && userEvents?.map((row, index) => (
              <UsersActivityRow row={row} key={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          padding: "10px",
          boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Pagination
          className="my-3"
          count={totalPages}
          page={currentPage}
          variant="outlined"
          onChange={(event, value) => setCurrentPage(value)}
        />
      </Box>
    </Box>
  );
}

export default observer(UsersActivityTable);
