import PageWrapper from "components/PageWrapper";
import TenantTable from "./intelliflo-tenant-table";

function Integrations() {

  return (
    <PageWrapper>
      <TenantTable />
    </PageWrapper>
  );
}

export default Integrations;
