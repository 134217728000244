import PageWrapper from "components/PageWrapper";
import IOConfigTable from "./io-config-table";
import { Box, Card } from "@mui/material";

function IOConfig() {

  return (
    <PageWrapper>
      <IOConfigTable />
    </PageWrapper>
  );
}

export default IOConfig;
