import { Box, Button, Divider, Grid, IconButton, Typography, useTheme } from "@mui/material";
import Header from "../../../components/Header";
import { useState } from "react";

import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";



import TrafficIcon from "@mui/icons-material/Traffic";
import StatBox from "../../../components/StatBox";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import SimplePieChart from "../../../components/Graphs/PieChart"; // Import the Pie Chart component


import ComposedGraph from "../../../components/Graphs/ComposedGraph";
import { useGetRequestsCountQuery } from "./api/dashboard.generated";
import PageWrapper from "components/PageWrapper";

const Dashboard = () => {
  const theme = useTheme();
  const colors = theme.palette;

  const [selectedPeriod, setSelectedPeriod] = useState<string>("last24hours");

  const { data: overallData } = useGetRequestsCountQuery();
  console.log("overalldata", overallData);

  const { data: filteredData, isFetching: isFilterDataLoading, isLoading } = useGetRequestsCountQuery({
    time: selectedPeriod // Send time range keyword directly to backend
  });

  const handlePeriodChange = (e: any) => {
    setSelectedPeriod(e.target.value); // Update selected period for dropdown
  };

  return (
    <Box sx={{ backgroundColor: colors.primary.light, minHeight: "100vh" }}>
      <Box sx={{ m: "40px" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
          <Box>
          </Box>
        </Box>
        <Grid spacing={2} container style={{ width: "100%" }}>
          <Grid item md={4} xs={10}>
            <Box
              sx={{
                gridColumn: "span 3",
                backgroundColor: colors.cardBackground,
                border: `1px solid ${colors.cardBorderColor}`,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Typography
                    variant="h4"
                    fontWeight="bold"
                    sx={{ color: colors.grey[100], textAlign: "center", marginBottom: "-5px" }}
                  >
                    {overallData?.getRequestsCount.totalItems?.toString() ??
                      "Loading..."}
                  </Typography>

                  <Typography
                    variant="h6"
                    sx={{
                      color: colors.blueAccent[600],
                      fontSize: "20px",
                      fontWeight: 500,
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Gateway Requests
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center", // Center content inside
                  alignItems: "center", // Vertical centering
                  marginLeft: "10px", // Add the margin-left to give space
                  flexGrow: 1, // Ensures it takes up remaining space
                  maxWidth: "120px", // Set max width for the pie chart container
                  maxHeight: "120px", // Set max height for the pie chart container
                }}
              >
                <SimplePieChart
                  successCount={overallData?.getRequestsCount.successCount ?? 0}
                  failureCount={overallData?.getRequestsCount.badGatewayCount ?? 0}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={8} xs={10}>
            <Box
              sx={{
                gridColumn: "span 8",
                gridRow: "span 2",
                backgroundColor: colors.cardBackground,
                border: `1px solid ${colors.cardBorderColor}`,
              }}
            >
              <Box
                mt="25px"
                padding="0 30px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography variant="h4" fontWeight="600" color={colors.grey[100]}>
                    Gateway Requests
                  </Typography>
                  <Typography variant="h3" fontWeight="500" color={colors.blueAccent[500]}>
                    {filteredData?.getRequestsCount?.groupedResults
                      ? filteredData.getRequestsCount.groupedResults.reduce((sum, item) => sum + (item.totalItems || 0), 0)
                      : "Loading..."}
                    {/* Show filtered totalItems */}
                  </Typography>
                </Box>
                {/* Dropdown for time filter */}
                <Box display="flex" justifyContent="flex-end" flexGrow={1}>
                  <FormControl sx={{ minWidth: 120 }}>
                    <InputLabel id="time-filter-label">Time Period</InputLabel>
                    <Select
                      labelId="time-filter-label"
                      id="time-filter"
                      value={selectedPeriod}
                      label="Time Period"
                      onChange={handlePeriodChange}
                      color="primary"
                    >
                      <MenuItem value="last24hours">Last 24 Hours</MenuItem>
                      <MenuItem value="last1month">Last 1 Month</MenuItem>
                      <MenuItem value="last3months">Last 3 Months</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  {/* <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.blueAccent[500] }}
                />
              </IconButton> */}
                </Box>
              </Box>
              <Box width="100%" height="250px" ml="-20px" mb="-10px">
                <ComposedGraph
                  data={(filteredData?.getRequestsCount?.groupedResults ?? []) as {
                    dateOrHour: string;
                    successCount: number;
                    badGatewayCount: number;
                    totalItems: number;
                  }[]}
                />


              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
