import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface User {
  username?: string;
  preferredMfa?: string;
  mfaOptions?: string[];
  email?: string;
  givenName?: string;
  familyName?: string;
}

const initialState: User = {};

export const userSlice = createSlice({
  name: "user",

  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state = {
        ...action.payload,
      };
    },
  },
});

export const { setUser } = userSlice.actions;
export const userSelector = (state: RootState) => state.userReducer;
export default userSlice.reducer;
