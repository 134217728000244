import { useTheme } from "@mui/material";
import React from "react";
import {
  PieChart,
  Pie,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";

interface PieChartProps {
  successCount: number;
  failureCount: number;
}

const SimplePieChart: React.FC<PieChartProps> = ({ successCount, failureCount }) => {
  const theme = useTheme();
  const colors = theme.palette;
  const data = [
    { name: "Success Request", value: successCount },
    { name: "Failure Request", value: failureCount },
  ];

  const COLORS = [colors.greenAccent[600], colors.redAccent[500]];

  return (
    <ResponsiveContainer width="100%" height={150} >
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={15}
          outerRadius={25}
          fill= { colors.primary.main }
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default SimplePieChart;
