import PageWrapper from "components/PageWrapper";
import AdviserUsersTable from "./adviser-users-table";


function AdviserUsers() {
  return (
    <PageWrapper>
      <AdviserUsersTable />
    </PageWrapper>
  );
}

export default AdviserUsers;
