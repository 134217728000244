import {
  Box,
  IconButton,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext } from "../../../theme";
import { useNavigate } from "react-router-dom";

import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import UpdateIcon from "@mui/icons-material/Update";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import zIndex from "@mui/material/styles/zIndex";
import { HalfMoon, LogOut, Refresh, Settings, SunLight, OpenNewWindow } from "iconoir-react";
import { useAuth } from "app/authHooks";

const Topbar = () => {
  const theme = useTheme();
  const auth = useAuth();

  const colorMode: any = useContext(ColorModeContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleLogoutClick = () => {
    setOpen(true);
  };
  const handleLogoutCancel = () => {
    setOpen(false);
  };

  const handleLogoutConfirm = () => {
    setOpen(false);
    auth.signOut();

    // localStorage.clear();
    // window.location.href = "/";
  };

  const handleChangelogClick = () => {
    navigate("/changelog");
  };

  const handleOpenDocHub = () => {
    window.open("https://docs-ds.portal.hubwise.co.uk/", "_blank");
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p={2}
      style={{ boxShadow: "0px 4px 20px rgba(64,110,184,0.16)", zIndex: "1" }}
    >
      <Box display="flex"></Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        <IconButton onClick={handleOpenDocHub}>
          <OpenNewWindow />
        </IconButton>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? <HalfMoon /> : <SunLight />}
        </IconButton>
        <IconButton onClick={handleChangelogClick}>
          <Refresh />
        </IconButton>
        <IconButton onClick={handleLogoutClick}>
          <LogOut />
        </IconButton>
      </Box>

      <Dialog open={open} onClose={handleLogoutCancel}>
        <DialogTitle>
          <Typography variant="h4">Confirm Logout</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleLogoutCancel}
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleLogoutConfirm}
            color="primary"
            autoFocus
            variant="contained"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Topbar;
