import { Box, useTheme } from "@mui/material";
import { DataGrid, GridColDef, GridPaginationModel, GridToolbar } from "@mui/x-data-grid";
import Header from "../../../components/Header";
import { useLocation, useNavigate, useParams } from "react-router";
import { getJobColumns } from "./JobTableColumn";
import { useEffect, useState } from "react";
import { useGetJobItemsQuery } from "./api/job-detail.generated";



const JobDetail = () => {
  const theme = useTheme();
  const colors = theme.palette;
  const { name } = useParams<{ name: string }>();
  const location = useLocation();
  const { title } = location.state || {};
  const navigate = useNavigate();
  const columns: GridColDef[] = getJobColumns(name, navigate);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 100,
    page: 0
  });
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any>(undefined);
  const [jobsCache, setJobsCache] = useState<Record<number, any[]>>({});
  const handlePaginationModelChange = (model: GridPaginationModel) => {
    if (model.page > paginationModel.page) {
      const nextLastEvaluatedKey = getJobItemResponse?.getJobItems?.lastEvaluatedKey;
      console.log("Last Evaluated Key: ", nextLastEvaluatedKey);
      if (nextLastEvaluatedKey) {
        if (name==="bulk-valuation") {
          setLastEvaluatedKey(nextLastEvaluatedKey);
        }
        else {
          if (!nextLastEvaluatedKey.id)
            return;
          const keyValue = nextLastEvaluatedKey.id.substring(5, nextLastEvaluatedKey.id.length-1);
          setLastEvaluatedKey( { id: keyValue } );
        }
      }
    }
    else if (model.page < paginationModel.page && jobsCache[model.page]) {
      setLastEvaluatedKey(undefined);
    }

    if (model.pageSize != paginationModel.pageSize) {
      setLastEvaluatedKey(undefined);
      setJobsCache({});
      setPaginationModel({
        pageSize: paginationModel.pageSize,
        page: 0
      });
    }
    setPaginationModel(model);
  };

  useEffect(() => {
    if (!name) {
      navigate("/jobs");
    }
  }, [name, navigate]);

  const { data: getJobItemResponse, isFetching } = useGetJobItemsQuery({
    jobName: name || "",
    limit: paginationModel.pageSize,
    lastEvaluatedKey: lastEvaluatedKey
  });
  const getJobTableData = (getJobItemResponse:any) => {
    if (getJobItemResponse?.getJobItems?.bulkValuationTableData)
      return getJobItemResponse?.getJobItems?.bulkValuationTableData;
    else if (getJobItemResponse?.getJobItems?.dataSyncTableData)
      return getJobItemResponse?.getJobItems?.dataSyncTableData;
    else if (getJobItemResponse?.getJobItems?.documentProcessTableData)
      return getJobItemResponse?.getJobItems?.documentProcessTableData;
  };

  useEffect(() => {
    if (getJobTableData(getJobItemResponse)) {
      const currentPage = paginationModel.page;
      setJobsCache((prev) => ({
        ...prev,
        [currentPage]: getJobTableData(getJobItemResponse) || [],
      }));
    }
  }, [getJobItemResponse, paginationModel.page]);


  const getJobItems = (name: string | undefined) => {
    const currentPage = paginationModel.page;
    if (jobsCache[currentPage]) {
      return jobsCache[currentPage];
    }
    switch (name) {
      case "document":
        return getJobItemResponse?.getJobItems?.documentProcessTableData || [];
      case "bulk-valuation":
        return getJobItemResponse?.getJobItems?.bulkValuationTableData || [];
      case "data-sync":
        return getJobItemResponse?.getJobItems?.dataSyncTableData || [];
      default:
        return [];
    }
  };

  const jobs = getJobItems(name);
  return (
    <Box m="20px">
      <Header
        title={title} subtitle={""} />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: `1px solid ${colors.cardBorderColor}`,
            borderRadius: "2px",
          },
          "& .MuiDataGrid-cell": {
          },
          "& .name-column--cell": {
            color: colors.grey[100],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: colors.cardBackground,
          },
          "& .MuiDataGrid-toolbarContainer": {
            backgroundColor: colors.cardBackground,
            borderBottom: `1px solid ${colors.cardBorderColor}`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]}`,
          },
          "& .MuiDataGrid-paper": {
            backgroundColor: `${colors.blueAccent[700]} !important`,
          },
        }}
      >
        <DataGrid
          rows={jobs}
          columns={columns}
          loading={isFetching}
          density="compact"
          slots={{ toolbar: GridToolbar }}
          pagination
          paginationModel={paginationModel}
          paginationMode="server"
          rowCount={getJobItemResponse?.getJobItems?.totalItems || 0}
          onPaginationModelChange={handlePaginationModelChange}
        />
      </Box>
    </Box>
  );
};

export default JobDetail;
