import React, { ReactNode } from "react";
import { Box, Card, useTheme } from "@mui/material";

const PageWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <Box style={{ backgroundColor: colors.primary.light, minHeight: "100vh" }}>
      <Card sx={{ m: "40px", paddingTop: "30px", paddingBottom: "30px", paddingRight: "15px", paddingLeft: "15px" }}>
        {children}
      </Card>
    </Box>
  );
};

export default PageWrapper;
