import { useNavigate } from "react-router";
import { Box, Chip, IconButton, Tooltip, useTheme } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const getJobColumns = (
  name: string | undefined,
  navigate: ReturnType<typeof useNavigate>
): GridColDef[] => {
  switch (name) {
    case "documents": {
      const documentJobColumns: GridColDef[] = [
        {
          field: "id",
          headerName: "ID",
          flex: 1,
        },
        {
          field: "documentId",
          headerName: "Document Id",
          flex: 1,
        },
        {
          field: "externalId",
          headerName: "External ID",
          flex: 1,
        },
        {
          field: "synchronised",
          headerName: "Synchronised",
          flex: 1,
        },
        {
          field: "type",
          headerName: "Type",
          flex: 1,
        },
        {
          field: "createdAt",
          headerName: "Created",
          valueGetter: (params: GridValueGetterParams) => {
            if (params.row.createdAt) {
              const dateSplit = params.row.createdAt.split("T");
              return `${dateSplit[0]} ${dateSplit[1].split(".")[0]}`;
            }
            return "";
          },
          flex: 1,
        },
        {
          field: "updatedAt",
          headerName: "Updated",
          valueGetter: (params: GridValueGetterParams) => {
            if (params.row.updatedAt) {
              const dateSplit = params.row.updatedAt.split("T");
              return `${dateSplit[0]} ${dateSplit[1].split(".")[0]}`;
            }
            return "";
          },
          flex: 1,
        },
        {
          field: "status",
          headerName: "Status",
          renderCell: (params) => {
            const { generated, uploaded, failed } = params.row;
            const props: { [key: string]: any } = {};
            if (failed) {
              props.color = "error";
              props.label = "Failed";
            } else if (generated) {
              props.color = "info";
              props.label = "Generated";
            } else if (uploaded) {
              props.color = "success";
              props.label = "Uploaded";
            } else {
              props.color = "default";
              props.label = "Pending";
            }
            return <Chip variant="outlined" {...props} />;
          },
          cellClassName: "status-column--cell",
          flex: 1,
        },
        {
          field: "action",
          headerName: "",
          renderCell: (params) => {
            return (
              <Tooltip title="Show Data">
                <IconButton
                  size="small"
                  onClick={() => navigate(`/jobs/${name}/${params.id}`)}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>

            );
          },
          width: 50,
        },
      ];
      return documentJobColumns;
    }
    case "bulk-valuation": {
      const bulkValuationJobColumns: GridColDef[] = [
        {
          field: "tenantId",
          headerName: "Tenant ID",
          flex: 1,
        },
        {
          field: "id",
          headerName: "ID",
          flex: 1,
        },
        {
          field: "externalId",
          headerName: "External ID",
          flex: 1,
        },
        {
          field: "internalId",
          headerName: "Internal ID",
          flex: 1,
        },
        {
          field: "itemsFailed",
          headerName: "Items Failed",
          flex: 1,
        },
        {
          field: "itemsImported",
          headerName: "Items Imported",
          flex: 1,
        },
        {
          field: "createdAt",
          headerName: "Created",
          valueGetter: (params: GridValueGetterParams) => {
            if (params.row.createdAt) {
              const dateSplit = params.row.createdAt.split("T");
              return `${dateSplit[0]} ${dateSplit[1].split(".")[0]}`;
            }
            return "";
          },
          flex: 1,
        },
        {
          field: "externalStatus",
          headerName: "External Status",
          flex: 1,
          renderCell: (params) => {
            const { externalStatus } = params.row;
            const props: { [key: string]: any } = {};
            if (externalStatus === "Failed") {
              props.color = "error";
              props.label = "Failed";
            } else if (externalStatus === "Completed") {
              props.color = "success";
              props.label = "Completed";
            }
            else if (externalStatus === "Pending") {
              props.color = "info";
              props.label = "Pending";
            } else {
              props.color = "default";
              props.label = externalStatus;
            }
            return <Chip variant="outlined" {...props} />;
          },
          cellClassName: "status-column--cell",
        },
        {
          field: "status",
          headerName: "Status",
          flex: 1,
          renderCell: (params) => {
            const { status } = params.row;
            const props: { [key: string]: any } = {};
            if (status === "Error") {
              props.color = "error";
              props.label = "Failed";
            } else if (status === "Completed") {
              props.color = "success";
              props.label = "Completed";
            } else {
              props.color = "default";
              props.label = status;
            }
            return <Chip variant="outlined" {...props} />;
          },
          cellClassName: "status-column--cell",
        },
        {
          field: "action",
          headerName: "",
          renderCell: (params) => {
            return (
              <Tooltip title="Show Data">
                <IconButton
                  size="small"
                  onClick={() => navigate(`/jobs/${name}/${params.id}`)}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            );
          },
          width: 50,
        },
      ];
      return bulkValuationJobColumns;
    }
    case "data-sync": {
      const dataSyncJobColumns: GridColDef[] = [
        {
          field: "tenant",
          headerName: "Tenant",
          flex: 1,
        },
        {
          field: "id",
          headerName: "ID",
          flex: 1,
        },
        {
          field: "customer",
          headerName: "Customer",
          flex: 1,
        },
        {
          field: "retryAttempt",
          headerName: "Retry Attempt",
          flex: 1,
        },
        {
          field: "statusReason",
          headerName: "Status Reason",
          flex: 1,
        },
        {
          field: "createdDate",
          headerName: "Created Date",
          valueGetter: (params: GridValueGetterParams) => {
            if (params.row.createdDate) {
              const dateSplit = params.row.createdDate.split("T");
              return `${dateSplit[0]} ${dateSplit[1].split(".")[0]}`;
            }
            return "";
          },
          flex: 1,
        },
        {
          field: "updatedTime",
          headerName: "Updated Time",
          valueGetter: (params: GridValueGetterParams) => {
            if (params.row.updatedTime) {
              const dateSplit = params.row.updatedTime.split("T");
              return `${dateSplit[0]} ${dateSplit[1].split(".")[0]}`;
            }
            return "";
          },
          flex: 1,
        },
        {
          field: "status",
          headerName: "Status",
          flex: 1,
          renderCell: (params) => {
            const { status } = params.row;
            const props: { [key: string]: any } = {};
            if (status === "failed") {
              props.color = "error";
              props.label = "Failed";
            } else if (status === "success") {
              props.color = "success";
              props.label = "Success";
            } else if (status === "Initiated") {
              props.color = "info";
              props.label = "Initiated";
            } else if (status === "ignored") {
              props.color = "default";
              props.label = "Ignored";
            } else {
              props.color = "default";
              props.label = status;
            }
            return <Chip variant="outlined" {...props} />;
          },
          cellClassName: "status-column--cell",
        },
        {
          field: "action",
          headerName: "",

          renderCell: (params) => {
            return (
              <Tooltip title="Show Data">
                <IconButton
                  size="small"
                  onClick={() => navigate(`/jobs/${name}/${params.id}`)}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            );
          },
          width: 50,
        },
      ];
      return dataSyncJobColumns;
    }
    default: {
      return [];
    }
  }
};
