export const paginationWrapper = <Hook, >(hook: Hook, path: string) => {
  const map: Record<string | number, string | undefined> = {};
  return (page: number): Hook => {
    //@ts-ignore
    return (rawParams, ...rest) => {
      const params = {
        LastEvaluatedKey: map[page - 1],
        ...(typeof rawParams === "object" ? rawParams : {}),
      };
      //@ts-ignore
      const result = hook(params, ...rest);
      if (result.data?.getAllEvents) {
        map[page] = result.data[path].LastEvaluatedKey;
      }
      if (result.data?.getAllIntellifloTenant) {
        console.log("res", result.data[path]?.data?.LastEvaluatedKey);
        map[page] = result.data[path]?.data?.LastEvaluatedKey;
      }
      if (result.data?.getAllIoConfig) {
        console.log("res", result.data[path]?.data?.LastEvaluatedKey);
        map[page] = result.data[path]?.data?.LastEvaluatedKey;
      }
      return result;
    };
  };
};
