import React, { useState } from "react";
import * as yup from "yup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion, AccordionDetails, AccordionSummary,
  Box, Button, MenuItem, Typography
} from "@mui/material";
import { Field, FieldArray, Form, Formik, FormikProps } from "formik";
import { TextInput } from "../../../../components/inputs";
import { SetState } from "../../../../app/types";
import { IntellifloTenantFilterInput } from "../../../../app/services/types.generated";

type FilterInput = Omit<IntellifloTenantFilterInput, "and" | "or" | "not">
const fields: { key: keyof FilterInput, label: string }[] = [
  { key: "id", label: "id" },
  { key: "externalId", label: "Intelliflo Tenant ID" },
  { key: "internalId", label: "Hubwise Customer Identifier" }
];

const operations = [
  { key: "eq", label: "Equal to" },
  { key: "ne", label: "Not equal to" },
  { key: "beginsWith", label: "Begins with" },
  { key: "between", label: "Between" },
  { key: "contains", label: "Contains" },
  { key: "notContains", label: "Not contains" },
  { key: "gt", label: "Greater then" },
  { key: "lt", label: "Less then" },
];

const validationSchema = yup.object({
  filters: yup.array(yup.object({
    name: yup.string().required().label("Name"),
    operation: yup.string().required().label("Operation"),
    value: yup
      .string()
      .when("operation", ([operation], schema) => operation === "between" ? schema.optional() : schema.required())
      .label("Value"),
  }))
});


export type InputValues = {
  name: keyof FilterInput | "",
  operation: string,
  value: string,
  from: string,
  to: string
};
type InitialValues = {
  filters: InputValues[]
}
const initialValues: InitialValues = {
  filters: [{ name: "", operation: "", value: "", from: "", to: "" }]
};

type Props = { setFilters: SetState<InputValues[] | null> };
const Filter: React.FC<Props> = ({ setFilters }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const onSubmit = (values: InitialValues) => {
    setFilters(values.filters);
  };

  return (
    <Box>
      <Accordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
        <AccordionSummary sx={{ "&.Mui-expanded": { minHeight: "32px" } }} expandIcon={<ExpandMoreIcon />}>
          <Typography>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "16px" }}>
          <Box>
            <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
              {(props: FormikProps<InitialValues>) => {
                const { values } = props;
                return (
                  <Form>
                    <FieldArray
                      name="filters"
                      render={arrayHelpers => (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 80px", gap: "20px" }}>
                            {values.filters.map((_, index) => (
                              <>
                                <Field
                                  select
                                  size="small"
                                  label="Name"
                                  component={TextInput}
                                  name={`filters[${index}].name`}
                                >
                                  {fields.map((el) => (
                                    <MenuItem key={el.key} value={el.key}>{el.label}</MenuItem>
                                  ))}
                                </Field>
                                <Field
                                  select
                                  size="small"
                                  label="Operation"
                                  component={TextInput}
                                  name={`filters[${index}].operation`}
                                >
                                  {operations.map((el) => (
                                    <MenuItem key={el.key} value={el.key}>{el.label}</MenuItem>
                                  ))}
                                </Field>
                                {values.filters[index].operation === "between"
                                  ? (
                                    <Box sx={{ display: "flex", gap: "20px" }}>
                                      <Field
                                        size="small"
                                        label="From"
                                        component={TextInput}
                                        name={`filters[${index}].from`}
                                      />
                                      <Field
                                        size="small"
                                        label="To"
                                        component={TextInput}
                                        name={`filters[${index}].to`}
                                      />
                                    </Box>
                                  ) : (
                                    <Field
                                      size="small"
                                      label="Value"
                                      component={TextInput}
                                      name={`filters[${index}].value`}
                                    />
                                  )
                                }
                                <Button
                                  type="button"
                                  size="small"
                                  variant="outlined"
                                  sx={{ maxHeight: "38px" }}
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  Remove
                                </Button>
                              </>
                            ))}
                          </Box>
                          <Box sx ={{ display:"flex", justifyContent: "flex-end", gap: 2 }}>
                            <Button
                              variant="outlined"
                              type="button"
                              onClick={() => arrayHelpers.push(initialValues.filters[0])}
                            >
                              Add a filter
                            </Button>
                            <Button variant="contained" type="submit">Search</Button>
                          </Box>

                        </Box>
                      )}
                    />
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default Filter;
