import {
  Alert, AlertTitle, Box, CircularProgress, Paper, Stack, Tab,
  Table, TableBody, TableCell,
  TableHead, TableRow, Tabs, Typography
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router";
import Header from "../../../components/Header";
import {
  GetStateMachineExecutionSummaryQueryVariables,
  useGetStateMachineExecutionSummaryQuery, useGetStateMachineSummaryQuery,
} from "./api/stateMachines.generated";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";

import "@tshepomgaga/aws-sfn-graph/index.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function parseDetailsMessage(detail: string) {
  const errorMessage = detail && JSON.parse(detail);
  if (errorMessage?.input) {
    try {
      errorMessage.input = JSON.parse(errorMessage.input, (key, value) => key === "trace" ? undefined : value);
    } catch (e) {
      // console.log(e);
    }
  }
  if (errorMessage?.cause) {
    try {
      errorMessage.cause = JSON.parse(errorMessage.cause, (key, value) => key === "trace" ? undefined : value);
      try {
        errorMessage.cause.errorMessage = JSON.parse(errorMessage.cause.errorMessage, (key, value) => key === "trace" ? undefined : value);
      } catch (e) {
        // console.log(e);
      }
    } catch (e) {
      // console.log(e);
    }
  }

  return JSON.stringify(errorMessage, null, 2);
}

const StateMachineExecutionSummary = () => {
  const params = useParams();
  const [tab, setTab] = useState(1);
  const { id = "", machineId = "" } = params;

  const stateMachineExecutionParams: GetStateMachineExecutionSummaryQueryVariables = {
    id,
  };

  const { data: summaryData } = useGetStateMachineSummaryQuery({ id: machineId });
  const stateMachine = summaryData?.getStateMachineSummary;

  const { data, isLoading } = useGetStateMachineExecutionSummaryQuery(stateMachineExecutionParams);
  const execution = data?.getStateMachineExecutionSummary;
  const events = execution?.events || [];

  if (isLoading) {
    return <CircularProgress/>;
  }

  const executionSucceed = events[0].type === "ExecutionSucceeded";
  const detail = events[0]?.detail;
  const errorMessage = !executionSucceed && parseDetailsMessage(detail);

  return (
    <Box m="20px">
      <Header title={execution ? `${execution.name}` : ""} subtitle={execution ? `${execution.id}` : ""}/>

      <Paper>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tab}
            onChange={(_, value) => setTab(value)}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Details" value={1}/>
            <Tab label="Execution input ond output" value={2}/>
            <Tab label="Definition" value={3}/>
          </Tabs>
        </Box>
        <Box mb={2}>
          <CustomTabPanel value={tab} index={1}>
            <Box display="grid" gridTemplateColumns="1fr 1fr" gap={1}>
              <Box>
                <Typography color="grey">Execution status:</Typography>
                {executionSucceed ? (
                  <Stack direction="row" alignItems="center" gap={0.25}>
                    <CheckCircleOutlineIcon color="success" />
                    <Typography color="green">
                      Succeeded
                    </Typography>
                  </Stack>
                ) : (
                  <Stack direction="row" alignItems="center" gap={0.25}>
                    <CancelOutlinedIcon color="error" />
                    <Typography color="error">
                      Failed
                    </Typography>
                  </Stack>
                )}
              </Box>
              <Box gridColumn="2/3">
                <Typography color="grey">Start date:</Typography>
                <Typography>{execution?.startDate}</Typography>
              </Box>
              <Box gridColumn="2/3">
                <Typography color="grey">End date:</Typography>
                <Typography>{execution?.stopDate}</Typography>
              </Box>
              <Box gridColumn="2/3">
                <Typography color="grey">Duration:</Typography>
                <Typography>{+(new Date(execution?.stopDate)) - +(new Date(execution?.startDate))}ms</Typography>
              </Box>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={2}>
            <Box display="grid" gridTemplateColumns="50% 50%" gap={1}>
              <Box>
                <Typography color="grey">Input:</Typography>
                <CodeMirror
                  editable={false}
                  extensions={[javascript()]}
                  value={parseDetailsMessage(data?.getStateMachineExecutionSummary?.input || "{}")}
                />
              </Box>
              <Box>
                <Typography color="grey">Output:</Typography>
                <CodeMirror
                  editable={false}
                  extensions={[javascript()]}
                  value={parseDetailsMessage(data?.getStateMachineExecutionSummary?.output || "{}")}
                />
              </Box>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={tab} index={3}>
            <CodeMirror
              editable={false}
              extensions={[javascript()]}
              value={stateMachine?.definition || "{}"}
            />
          </CustomTabPanel>
        </Box>
      </Paper>

      {errorMessage && (
        <Alert severity="error">
          <AlertTitle>Execution failed with error message:</AlertTitle>
          <CodeMirror
            editable={false}
            value={errorMessage}
            extensions={[javascript()]}
          />
        </Alert>
      )}

      <Table>
        <TableHead>
          <TableCell>ID</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Previous ID</TableCell>
          <TableCell>Date & Time</TableCell>
        </TableHead>
        <TableBody>
          {events.map(event => (
            <TableRow key={event.id}>
              <TableCell>{event.id}</TableCell>
              <TableCell>{event.type}</TableCell>
              <TableCell>{event.previousEventId}</TableCell>
              <TableCell>{event.timestamp}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
export default StateMachineExecutionSummary;
